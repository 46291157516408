import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  triggerContainerScrolled: false,
  reservationConfirmationDetails: null,
  language: localStorage.getItem('language') || 'lv',
};

export const variables = createSlice({
  name: 'app',
  initialState,
  reducers: {
    updateTriggerContainerScrolled: (state, action) => {
      state.triggerContainerScrolled = action.payload;
    },
    updateReservationConfirmationDetails: (state, action) => {
      state.reservationConfirmationDetails = action.payload;
    },
    changeLanguage: (state, action) => {
      localStorage.setItem('language', action.payload);
      state.language = action.payload;
    },
  },
});

export const { updateTriggerContainerScrolled, updateReservationConfirmationDetails, changeLanguage } = variables.actions;
export const selectTriggerContainerScrolled = (state) => state.app.triggerContainerScrolled;
export const selectReservationConfirmationDetails = (state) => state.app.reservationConfirmationDetails;
export const selectLanguage = (state) => state.app.language;

export default variables.reducer;
