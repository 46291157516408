import React from "react";
import styled from "styled-components";

//components
import SectionHeroSimple from "../components/SectionHeroSimple";

//data
import { rules } from "../data/rulesData";

//helmet
import { Helmet } from "react-helmet";

//locales
import { useTranslation } from "react-i18next";

const Policy = () => {
  const { t } = useTranslation();

  return (
    <Wrapper>
      <Helmet>
        <title>{t("rules_helmet", { ns: "rules" })} - Kempings Plaucaki</title>
        <meta name="title" content="Noteikumi - Kempings Plaucaki" />
        <link rel="canonical" href="https://plaucaki.lv/rules" />
        <meta
          name="description"
          content="Pirms veicat rezervāciju, lūgums iepazīties ar kempinga noteikumiem."
        />

        <meta property="og:title" content="Noteikumi - Kempings Plaucaki" />
        <meta property="og:url" content="https://plaucaki.lv/rules" />
        <meta
          property="og:desc"
          content="Pirms veicat rezervāciju, lūgums iepazīties ar kempinga noteikumiem."
        />
        <meta
          property="og:image"
          content="https://plaucaki.lv/assets/seo/seo-img.png"
        />
      </Helmet>

      <SectionHeroSimple data={{ heading: t("rules_heading", { ns: "rules" }) }} />

      <DataList id="rules" className="container-center">
        <div className="data__item">
          <ul>
            {rules?.map((rule, i) => (
              <li key={i}>{t(rule, { ns: "rules" })}</li>
            ))}
          </ul>
        </div>

        <p>{t("rules_date", { ns: "rules" })}: 12.08.2024</p>
      </DataList>
    </Wrapper>
  );
};

const DataList = styled.section`
  margin: 80px auto 40px;

  .data__item {
    margin: 0 0 32px 0;

    h3 {
      margin: 0 0 16px 0;
    }

    ul {
      list-style-type: decimal;
      padding: 0 20px;

      li {
        margin: 0 0 16px 0;
      }
    }
  }
`;

const Wrapper = styled.main``;

export default Policy;
