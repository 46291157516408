import React, { useRef } from "react";
import styled from "styled-components";
import { motion, useInView } from "framer-motion";

import reviewsList from "../../data/reviewsData";

//locales
import { useTranslation } from "react-i18next";

const Reviews = () => {
  const { t } = useTranslation();
  const ref = useRef(null);
  const isInView = useInView(ref, { once: true });

  const variants = {
    hidden: { opacity: 0, y: 50 },
    visible: { opacity: 1, y: 0, transition: { duration: 0.6 } },
  };

  const ReviewItem = ({ data }) => {
    const { stars, review, imageUrl, name } = data;

    return (
      <Review>
        <div className="review__stars">
          {[...Array(stars)].map((_, index) => (
            <img
              key={index}
              src="/assets/icons/star.svg"
              alt={`review-star-${index}`}
            />
          ))}
        </div>

        <div className="review__text">
          <p>{t(review, { ns: "reviews" })}</p>
        </div>

        <div className="review__author">
          <img src={imageUrl} alt="review-author" />
          <p className="text-sm">{name}</p>
        </div>
      </Review>
    );
  };

  return (
    <Wrapper
      id="reviews"
      ref={ref}
      initial="hidden"
      animate={isInView ? "visible" : "hidden"}
      variants={variants}
    >
      <div className="reviews__container container-center">
        <div className="reviews__container__marquee">
          <div className="reviews__container__marquee__column">
            {reviewsList?.slice(0, 4).map((data) => {
              return <ReviewItem key={data?.id} data={data} />;
            })}
          </div>
        </div>

        <div className="reviews__container__marquee">
          <div className="reviews__container__marquee__column__reverse">
            {reviewsList?.slice(4, 9).map((data) => {
              return <ReviewItem key={data?.id} data={data} />;
            })}
          </div>
        </div>

        <div className="reviews__container__fade__top" />
        <div className="reviews__container__fade__bottom" />
      </div>
    </Wrapper>
  );
};

const Review = styled.div`
  background-color: var(--lightgrey);
  padding: 16px;
  box-sizing: border-box;
  border-radius: 8px;
  max-width: 100%;
  margin: 0 0 16px 0;

  .review__stars {
    display: flex;
    gap: 8px;

    img {
      width: 24px;
      height: 24px;
      object-fit: contain;
    }
  }

  .review__text {
    margin: 16px 0 32px;
    line-height: 1.5rem;
  }

  .review__author {
    display: flex;
    gap: 16px;
    align-items: center;

    img {
      width: 24px;
      height: 24px;
      border-radius: 50%;
    }
  }
`;

const Wrapper = styled(motion.section)`
  padding: 80px 0;

  .reviews__container {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 16px;
    height: 500px;
    overflow: hidden;
    position: relative;

    @media screen and (max-width: 600px) {
      padding: 0px !important;
    }

    &__fade__top {
      width: 100%;
      height: 100px;
      background: linear-gradient(var(--white), transparent);
      position: absolute;
      top: 0;
      left: 0;
    }

    &__fade__bottom {
      width: 100%;
      height: 100px;
      background: linear-gradient(transparent, var(--white));
      position: absolute;
      bottom: 0;
      left: 0;
    }

    &__marquee {
      display: flex;
      flex-direction: column;
      height: 100%;
      position: relative;
      width: 100%;

      &__column {
        display: flex;
        flex-direction: column;
        animation: marquee 40s linear infinite;
        height: 100%;

        &:hover {
          animation-play-state: paused;
        }

        @keyframes marquee {
          0% {
            transform: translateY(-100%);
          }
          100% {
            transform: translateY(500px);
          }
        }

        &__reverse {
          display: flex;
          flex-direction: column-reverse;
          animation: marqueeReverse 40s linear infinite;
          height: 100%;

          &:hover {
            animation-play-state: paused;
          }

          @keyframes marqueeReverse {
            0% {
              transform: translateY(500px);
            }
            100% {
              transform: translateY(-100%);
            }
          }
        }
      }
    }
  }
`;

export default Reviews;
