import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { useParams, useLocation, useNavigate } from "react-router-dom";
import axios from "axios";

//redux
import { updateTriggerContainerScrolled } from "../redux/appState";
import { useDispatch } from "react-redux";

//components
import Hero from "../components/accommodation/Hero";
import Description from "../components/accommodation/Description";
import ReservationForm from "../components/accommodation/ReservationForm";

//data
import {
  accommodationHouseInformation,
  accommodationTentInformation,
  accommodationTrailerInformation,
} from "../data/accommodationData";

//helmet
import { Helmet } from "react-helmet";

const Accommodation = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(updateTriggerContainerScrolled(true));

    return () => {
      dispatch(updateTriggerContainerScrolled(false));
    };
  }, [dispatch]);

  //Accommodation handling
  const { id } = useParams();
  const location = useLocation();
  const { pathname } = location;
  const navigate = useNavigate();

  const [accommodation, setAccommodation] = useState("");

  const getBookedDates = async (id) => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_BASE_URL}/bookings.php?id=${id}`,
        {
          headers: {
            "X-API-KEY": process.env.REACT_APP_API_KEY,
          },
        }
      );

      const { data } = response;
      return data;
    } catch (error) {
      console.error("Error fetching booked dates:", error);
      return [];
    }
  };

  useEffect(() => {
    const fetchAccommodationData = async () => {
      if (pathname === "/tents") {
        setAccommodation(accommodationTentInformation);
      } else if (pathname === "/trailers") {
        setAccommodation(accommodationTrailerInformation);
      } else if (id <= 12 && id > 0) {
        const selectedHouse =
          accommodationHouseInformation?.accommodations?.filter(
            (accommodation) => accommodation.id === parseInt(id)
          );

        let houseData = selectedHouse[0];

        await getBookedDates(id).then((response) => {
          houseData = {
            ...houseData,
            description: accommodationHouseInformation?.description,
            amenities: accommodationHouseInformation?.amenities,
            bookedDates: response,
          };

          setAccommodation(houseData);
        });
      } else {
        navigate("/houses");
      }
    };

    fetchAccommodationData();
    //eslint-disable-next-line
  }, [pathname]);

  useEffect(() => {
    if (accommodation && accommodation?.images?.length < 3) {
      function getRandomNumber(maxNumber) {
        const randomNumber = Math.floor(Math.random() * maxNumber);
        return randomNumber;
      }

      const randomNumber = getRandomNumber(8);
      let updatedImages = accommodation?.images;
      updatedImages = [...updatedImages, `/assets/gallery/${randomNumber}.png`];

      setAccommodation((accommodation) => ({
        ...accommodation,
        images: updatedImages,
      }));
    }

    //eslint-disable-next-line
  }, [accommodation]);
  //Accommodation handling -- end

  return (
    <Wrapper>
      {accommodation?.name && (
        <Helmet>
          <title>{`${accommodation.name} - Kempings Plaucaki`}</title>
          <meta
            name="title"
            content={`${accommodation.name} - Kempings Plaucaki`}
          />
          <link
            rel="canonical"
            href={`https://plaucaki.lv/houses/${accommodation.id}`}
          />
          <meta name="description" content={accommodation.description?.[0]} />
          <meta
            property="og:url"
            content={`https://plaucaki.lv/houses/${accommodation.id}`}
          />
          <meta property="og:desc" content={accommodation.description?.[0]} />
          <meta
            property="og:title"
            content={`${accommodation.name} - Kempings Plaucaki`}
          />
          <meta
            property="og:image"
            content={`https://plaucaki.lv${accommodation.images?.[0]}`}
          />
        </Helmet>
      )}
      
      <AccommodationWrapper>
        <Hero images={accommodation?.images} />

        <AccommodationBody className="container-center">
          <div className="container">
            <Description data={accommodation} />
            <ReservationForm data={accommodation} />
          </div>
        </AccommodationBody>
      </AccommodationWrapper>
    </Wrapper>
  );
};

const AccommodationBody = styled.section`
  padding: 40px 20px;

  .container {
    display: flex;
    gap: 32px;

    @media screen and (max-width: 960px) {
      flex-direction: column;
    }
  }
`;

const AccommodationWrapper = styled.main``;

const Wrapper = styled.div``;

export default Accommodation;
