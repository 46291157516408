import React, { useEffect } from "react";

//router
import { Routes, Route, Outlet, Navigate } from "react-router-dom";

//components
import Header from "./components/Header";
import Footer from "./components/Footer";
import CookiesModal from "./components/CookiesModal";
import { ToastContainer, Flip } from "react-toastify";
import ContactIcon from "./components/ContactIcon";

//pages
import Home from "./pages/Home";
import Rules from "./pages/Rules";
import Policy from "./pages/Policy";
import Houses from "./pages/Houses";
import Pricing from "./pages/Pricing";
import Accommodation from "./pages/Accommodation";
import Reserve from "./pages/Reserve";
import Confirmation from "./pages/Confirmation";
import Gallery from "./pages/Gallery";

//admin pages
import Dashboard from "./pages/admin/Dashboard";
import Calendar from "./pages/admin/Calendar";
import Login from "./pages/admin/Login";

//admin components
import AdminHeader from "./components/admin/AdminHeader";
import AdminFooter from "./components/admin/AdminFooter";
import PrivateRoute from "./components/admin/PrivateRoute";

//helmet
import { Helmet } from "react-helmet";

//redux
import { useDispatch, useSelector } from "react-redux";
import { checkAuthState } from "./redux/authState";
import { selectLanguage, changeLanguage } from "./redux/appState";

//locales
import i18n from "./i18n";

//google analytics
import ReactGA from "react-ga4";
ReactGA.initialize(process.env.REACT_APP_GOOGLE_ANALYTICS_KEY);

function App() {
  const language = useSelector(selectLanguage);
  const dispatch = useDispatch();

  const LanguageChangeWithUrl = ({ to, beforeNavigate }) => {
    beforeNavigate && beforeNavigate();
    return <Navigate to={to} />;
  };

  useEffect(() => {
    dispatch(checkAuthState());
  }, [dispatch]);

  useEffect(() => {
    i18n.changeLanguage(language);
  }, [language]);

  return (
    <div>
      <Helmet>
        <meta charSet="utf-8" />
        <link rel="canonical" href="https://plaucaki.lv/" />

        <meta property="og:type" content="website" />
        <meta
          property="og:site_name"
          content="Kempings Plaucaki - Pūrciems, Latvija"
        />
        <meta property="og:locale" content="lv_LV" />
        <meta property="og:type" content="website" />
        <meta property="og:url" content="https://plaucaki.lv/" />
        <meta
          property="og:description"
          content="Izbaudiet dabas skaistumu un pilnīgu mieru Kempingā Plaucaki, Latvijā. Ideāla vieta, lai atpūstos no ikdienas steigas un iegremdētos klusumā, tikai dažu soļu attālumā no jūras."
        />
        <meta
          property="og:image"
          content="https://plaucaki.lv/assets/seo/seo-img.png"
        />

        <meta name="googlebot" content="notranslate" />
        <meta name="rating" content="safe for kids" />
        <meta name="author" content="Ansis Arturs Irbe" />
        <meta
          name="keywords"
          content="kempings Latvijā, Plaucaki kempings, Baltā kāpa, romantiskas koka mājiņas, telšu vietas, treileru vietas, jūras tuvums, dabas skaistums, miera sajūta, saskaņa ar dabu, ainaviskās takas, ugunskura vietas, elektrības pieslēgums treileriem, pastaigas Baltā kāpa, dabas klusums, atpūta pie jūras, ģimenes atpūta, dabas izpēte, kempinga mājiņas, atpūtas vieta, ūdens uzpildes vieta, kempinga teritorija, Purciems, Roja, Kurzeme"
        />
        <meta
          name="description"
          content="Izbaudiet dabas skaistumu un pilnīgu mieru Kempingā Plaucaki, Latvijā. Ideāla vieta, lai atpūstos no ikdienas steigas un iegremdētos klusumā, tikai dažu soļu attālumā no jūras."
        />
      </Helmet>

      <Routes>
        <Route
          path="/"
          element={
            <div className="container">
              <Header />
              <Outlet />
              <Footer />
            </div>
          }
        >
          <Route path="/" element={<Home />} />
          <Route path="/rules" element={<Rules />} />
          <Route path="/privacy-policy" element={<Policy />} />
          <Route path="/houses" element={<Houses />} />
          <Route path="/pricing" element={<Pricing />} />
          <Route path="/houses/:id" element={<Accommodation />} />
          <Route path="/tents" element={<Accommodation />} />
          <Route path="/trailers" element={<Accommodation />} />
          <Route path="/reserve" element={<Reserve />} />
          <Route path="/confirmation" element={<Confirmation />} />
          <Route path="/gallery" element={<Gallery />} />
          <Route path="*" element={<Navigate to="/" />} />
          <Route
            path="/lv"
            element={
              <LanguageChangeWithUrl
                to="/"
                beforeNavigate={() => dispatch(changeLanguage("lv"))}
              />
            }
          />
          <Route
            path="/en"
            element={
              <LanguageChangeWithUrl
                to="/"
                beforeNavigate={() => dispatch(changeLanguage("en"))}
              />
            }
          />
          <Route
            path="/ru"
            element={
              <LanguageChangeWithUrl
                to="/"
                beforeNavigate={() => dispatch(changeLanguage("ru"))}
              />
            }
          />
        </Route>

        <Route
          path="/admin"
          exact={true}
          element={
            <PrivateRoute>
              <div
                className="admin-container"
                style={{
                  minHeight: "100vh",
                  backgroundColor: "var(--lightgreyd)",
                }}
              >
                <AdminHeader />
                <Outlet />
                <AdminFooter />
              </div>
            </PrivateRoute>
          }
        >
          <Route path="dashboard" element={<Dashboard />} />
          <Route path="calendar" element={<Calendar />} />
        </Route>

        <Route path="/admin/login" element={<Login />} />
      </Routes>

      <ToastContainer
        position="top-center"
        autoClose={3000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
        transition={Flip}
      />

      <ContactIcon />
      <CookiesModal />
    </div>
  );
}

export default App;
