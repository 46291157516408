import React from "react";
import styled from "styled-components";

//components
import SectionHeroSimple from "../components/SectionHeroSimple";

//data
import { policy } from "../data/policyData";

//helmet
import { Helmet } from "react-helmet";

//locales
import { useTranslation } from "react-i18next";

const Policy = () => {

  const { t } = useTranslation();

  return (
    <Wrapper>
      <Helmet>
        <title>{t("policy_helmet", { ns: "policy" })} - Kempings Plaucaki</title>
        <meta name="title" content="Privātuma politika - Kempings Plaucaki" />
        <link rel="canonical" href="https://plaucaki.lv/privacy-policy" />
        <meta
          name="description"
          content="Mēs paturam tiesības laiku pa laikam atjaunināt šo Privātuma politiku. Jebkādas izmaiņas stājas spēkā nekavējoties, publiskojojot pārstrādāto Privātuma politiku mūsu tīmekļa vietnē."
        />

        <meta
          property="og:title"
          content="Privātuma politika - Kempings Plaucaki"
        />
        <meta property="og:url" content="https://plaucaki.lv/privacy-policy" />
        <meta
          property="og:desc"
          content="Mēs paturam tiesības laiku pa laikam atjaunināt šo Privātuma politiku. Jebkādas izmaiņas stājas spēkā nekavējoties, publiskojojot pārstrādāto Privātuma politiku mūsu tīmekļa vietnē."
        />
        <meta
          property="og:image"
          content="https://plaucaki.lv/assets/seo/seo-img.png"
        />
      </Helmet>

      <SectionHeroSimple data={{ heading: t("policy_helmet", { ns: "policy" })}} />

      <DataList id="privacy-policy" className="container-center">
        {policy?.map((item, i) => {
          const { heading, list } = item;

          return (
            <div className="data__item" key={i}>
              <h3 className="text-lg">{t(heading, { ns: "policy" })}</h3>
              <ul>
                {list?.map((policy, i) => (
                  <li key={i}>{t(policy, { ns: "policy" })}</li>
                ))}
              </ul>
            </div>
          );
        })}

        <p>{t("policy_date", { ns: "policy" })}: 12.08.2024</p>
      </DataList>
    </Wrapper>
  );
};

const DataList = styled.section`
  margin: 80px auto 40px;

  .data__item {
    margin: 0 0 32px 0;

    h3 {
      margin: 0 0 16px 0;
    }

    ul {
      list-style-type: decimal;
      padding: 0 20px;

      li {
        margin: 0 0 16px 0;
      }
    }
  }
`;

const Wrapper = styled.main``;

export default Policy;
