import React from "react";
import styled from "styled-components";
import { Link } from "react-router-dom";

//icons
import { DiagonalArrowRightUpOutline as Arrow } from "@styled-icons/evaicons-outline/DiagonalArrowRightUpOutline";

//locales
import { useTranslation } from "react-i18next";

const Description = ({ data }) => {
  const { t } = useTranslation();
  const { name, persons, price, description, amenities } = data;

  return (
    <Wrapper id="accommodation-data">
      <div className="container__left">
        <div className="container__left__heading">
          <h1 className="text-3xl">{name}</h1>

          <div className="container__left__heading__reviews">
            <div className="container__left__heading__reviews__stars">
              <p className="text-sm">4,6</p>
              <img src="/assets/icons/star.svg" alt="review-star" />
              <img src="/assets/icons/star.svg" alt="review-star" />
              <img src="/assets/icons/star.svg" alt="review-star" />
              <img src="/assets/icons/star.svg" alt="review-star" />
              <img src="/assets/icons/half-star.svg" alt="review-star" />
            </div>
            <Link
              target="_blank"
              to="https://g.co/kgs/bWdfCPT"
              className="text-sm"
            >
              70+ {t("accommodation_desc_reviews", { ns: "accommodations" })}
            </Link>
          </div>
        </div>

        <div className="container__left__description">
          <p>
            {t("accommodation_desc_address", { ns: "accommodations" })} | {name}{" "}
            | 1-
            {persons}{" "}
            {t("accommodation_desc_persons", { ns: "accommodations" })} | €
            {price}{" "}
            {t("accommodation_desc_per_night", { ns: "accommodations" })}
          </p>

          <ul>
            {description?.map((data, i) => (
              <li key={i}>{t(data, { ns: "accommodations" })}</li>
            ))}
          </ul>
        </div>

        <div className="container__left__amenities">
          <ul>
            {amenities?.map((data, i) => {
              const { img, name, desc } = data;

              return (
                <li key={i}>
                  <img src={img} alt="amenity-img" />
                  <div className="amenity-data">
                    <p className="amenity-name">{t(name, { ns: "accommodations" })}</p>
                    {desc && <p className="amenity-description">{t(desc, { ns: "accommodations" })}</p>}
                  </div>
                </li>
              );
            })}
          </ul>
        </div>

        <Link
          className="container__left__rules__link"
          to="/rules"
          target="_blank"
        >
          <p>{t("accommodation_desc_rules", { ns: "accommodations" })}</p>
          <Arrow className="icon" />
        </Link>
      </div>
    </Wrapper>
  );
};

const Wrapper = styled.section`
  flex-grow: 1;

  .container__left {
    &__rules__link {
      color: var(--green);
      display: flex;
      gap: 8px;

      p {
        color: var(--green);
      }

      .icon {
        max-width: 18px;
        width: 100%;
        object-fit: contain;
      }
    }

    &__amenities {
      margin: 40px 0;

      ul {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        grid-gap: 16px;

        li {
          display: flex;
          align-items: flex-start;
          gap: 16px;

          img {
            max-width: 24px;
            min-width: 24px;
            width: 100%;
            object-fit: contain;
          }

          .amenity-name {
            font-weight: 500;
          }

          .amenity-description {
            color: var(--green);
            margin-top: 8px;
          }
        }
      }
    }

    &__description {
      margin: 16px 0;

      ul {
        margin: 16px 0 0 0;
        display: flex;
        flex-direction: column;
        gap: 24px;

        li {
          line-height: 1.4rem;
        }
      }
    }

    &__heading {
      display: flex;
      justify-content: space-between;
      gap: 16px;

      &__reviews {
        display: flex;
        flex-direction: column;
        align-items: flex-end;

        a {
          text-decoration: underline;
          color: var(--green);
        }

        a:hover {
          text-decoration: none;
        }

        &__stars {
          display: flex;
          align-items: center;

          p {
            margin-right: 4px;
            color: var(--green);
          }

          img {
            max-width: 24px;
            min-width: 24px;
            width: 100%;
            object-fit: contain;
          }
        }
      }
    }
  }
`;

export default Description;
