export const rules = [
  "rules1",
  "rules2",
  "rules3",
  "rules4",
  "rules5",
  "rules6",
  "rules7",
  "rules8",
  "rules9",
  "rules10",
  "rules11",
  "rules12",
  "rules13",
];
