import React, { useState, useEffect } from "react";
import styled from "styled-components";

//components

const PageHeading = ({text}) => {
  const [greeting, setGreeting] = useState("");

  const getGreeting = () => {
    const currentHour = new Date().getHours();

    if (currentHour < 12) {
      return "Labrīt";
    } else if (currentHour < 18) {
      return "Labdien";
    } else {
      return "Labvakar";
    }
  };

  useEffect(() => {
    const message = getGreeting();
    setGreeting(message);
  }, []);

  return (
    <Wrapper>
      <h3 className='text-xl'>{`${greeting}!`}</h3>
      <p>{text}</p>
    </Wrapper>
  );
};

const Wrapper = styled.section`
  padding: 40px 0;

  h3 {
    margin: 0 0 8px 0;
  }
`;

export default PageHeading;
