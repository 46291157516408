import React, { useState, useEffect } from "react";
import styled from "styled-components";

const Alert = ({ type, text }) => {
  const [alertColor, setAlertColor] = useState("");

  useEffect(() => {
    if (type === "error") {
      setAlertColor("var(--alert)");
    }

    //eslint-disable-next-line
  }, []);

  return <Wrapper style={{ backgroundColor: alertColor }}>{text}</Wrapper>;
};

const Wrapper = styled.div`
color: var(--white);
padding: 12px 16px;
border-radius: 8px;
`;

export default Alert;
