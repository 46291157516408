// src/navigationData.js
const navigationData = {
  navigation: [
    { id: 0, name: "home", link: "/" },
    { id: 1, name: "houses", link: "/houses" },
    { id: 2, name: "tent_spaces", link: "/tents" },
    { id: 3, name: "trailer_spaces", link: "/trailers" },
    { id: 4, name: "pricing", link: "/pricing" },
    { id: 5, name: "gallery", link: "/gallery" },
  ],

  navigationWithoutCampsite: [
    { id: 0, name: "home", link: "/" },
    { id: 4, name: "pricing", link: "/pricing" },
    { id: 5, name: "gallery", link: "/gallery" },
  ],

  navigationCampsite: [
    { id: 1, name: "houses", link: "/houses" },
    { id: 2, name: "tent_spaces", link: "/tents" },
    { id: 3, name: "trailer_spaces", link: "/trailers" },
  ],

  navigationInformation: [
    { id: 1, name: "rules", link: "/rules" },
    { id: 2, name: "privacy_policy", link: "/privacy-policy" },
    { id: 3, name: "phone_number", link: "tel:+37126445124" },
    { id: 4, name: "email", link: "mailto:info@plaucaki.lv" },
  ]
};

export default navigationData;
