import React, { useState, useEffect, useRef } from "react";
import styled from "styled-components";
import { Link, NavLink } from "react-router-dom";
import { motion } from "framer-motion";
import axios from "axios";

//data
import navigationData from "../data/navigationData";

//hooks
import useScrollPosition from "../hooks/useScrollPosition";
import isTodayDisabled from "../hooks/isTodayDisabled";
import useTriggerContainerScrolled from "../hooks/useTriggerContainerScrolled";

//components
import { Slant as Hamburger } from "hamburger-react";

//icons
import { DiagonalArrowRightUpOutline as Arrow } from "@styled-icons/evaicons-outline/DiagonalArrowRightUpOutline";
import { Close } from "@styled-icons/ionicons-outline/Close";
import { Checkmark } from "@styled-icons/evaicons-solid/Checkmark";

//redux
import { changeLanguage, selectLanguage } from "../redux/appState";
import { useDispatch, useSelector } from "react-redux";

//locales
import { useTranslation } from "react-i18next";

const Header = () => {
  const navigation = navigationData.navigation;
  const scrollPosition = useScrollPosition();
  const dispatch = useDispatch();
  const language = useSelector(selectLanguage);

  const [containerScrolled, setContainerScrolled] = useState(false);
  const [containerScrolledPastHero, setContainerScrolledPastHero] =
    useState(false);
  const triggerContainerScrolled = useTriggerContainerScrolled();

  const { t } = useTranslation();

  const initialLanguage = [
    {
      id: "lv",
      nameLat: "Latviski",
      nameCountry: "Latviski",
      active: true,
    },
    {
      id: "en",
      nameLat: "Angliski",
      nameCountry: "English",
      active: false,
    },
    {
      id: "ru",
      nameLat: "Krieviski",
      nameCountry: "Русский",
      active: false,
    },
  ];

  const [languageOptions, setLanguageOptions] = useState(initialLanguage);
  const [isLanguageModal, setLanguageModal] = useState(false);

  const handleLanguageModal = () => {
    setLanguageModal(!isLanguageModal);
  };

  const handleLanguageChange = (selectedId) => {
    const updatedLanguageOptions = languageOptions.map((language) => {
      return {
        ...language,
        active: language.id === selectedId,
      };
    });

    dispatch(changeLanguage(selectedId));
    setLanguageOptions(updatedLanguageOptions);
  };

  //check language on load
  useEffect(() => {
    handleLanguageChange(language);
    //eslint-disable-next-line
  }, []);

  //mobile navigation
  const [isOpen, setOpen] = useState(false);

  const ref = useRef(null);
  const variants = {
    hidden: { opacity: 0, y: `-100vh` },
    visible: { opacity: 1, y: 0, transition: { duration: 0.3 } },
  };

  const handleCloseNavigation = () => {
    setOpen(false);
  };

  useEffect(() => {
    if (isOpen) {
      document.body.style.overflowY = "hidden";
    } else {
      document.body.style.overflowY = "auto";
    }

    return () => {
      document.body.style.overflowY = "auto";
    };
  }, [isOpen]);

  //mobile navigation end

  //is campsite opened
  const campsiteStatus = isTodayDisabled();
  //is campsite opened --end

  //campsite temperature
  const [temperature, setTemperature] = useState(null);

  useEffect(() => {
    axios
      .get(`${process.env.REACT_APP_API_BASE_URL}/temperature.php`, {
        headers: {
          "X-API-KEY": process.env.REACT_APP_API_KEY,
        },
      })
      .then((response) => {
        const { data } = response;

        // Function to extract numbers from a string
        const extractNumbers = (input) => {
          const matches = input.match(/[\d.]+/g);
          return matches ? matches.map(Number) : [];
        };

        // Extract numbers from each temperature string
        const temperatureNumber = extractNumbers(data.temperature);
        const waterTemperatureNumber = extractNumbers(data.water_temperature);

        setTemperature({
          temperature: temperatureNumber[0],
          waterTemperature: waterTemperatureNumber[0],
        });
      });
  }, []);
  //campsite temperature --end

  useEffect(() => {
    if (scrollPosition > 80) {
      setContainerScrolled(true);
    } else {
      setContainerScrolled(false);
    }

    if (scrollPosition > 350) {
      setContainerScrolledPastHero(true);
    } else {
      setContainerScrolledPastHero(false);
    }
  }, [scrollPosition]);

  const isStyleApplied = containerScrolled || triggerContainerScrolled;
  const backgroundColor = isStyleApplied ? "var(--white)" : "transparent";
  const textColor = isStyleApplied ? "var(--black)" : "var(--white)";
  const iconFilter = isStyleApplied
    ? "invert(51%) sepia(14%) saturate(1165%) hue-rotate(127deg) brightness(90%) contrast(88%)"
    : "none";
  const imageToShow = isStyleApplied
    ? "/assets/big-icon.png"
    : "/assets/big-icon-white.png";

  return (
    <Wrapper style={{ backgroundColor }}>
      <div className="navbar">
        <div className="navbar__top container-center">
          <div className="navbar__top__left">
            {temperature && (
              <>
                <motion.div
                  className="navbar__top__left__item"
                  initial={{ opacity: 0, x: -20 }}
                  animate={{ opacity: 1, x: 0 }}
                  transition={{ duration: 0.4 }}
                >
                  <img
                    src="/assets/icons/sun.svg"
                    alt="weather-icon"
                    style={{ filter: iconFilter }}
                  />
                  <p className="text-xs" style={{ color: textColor }}>
                    {temperature?.temperature}°C
                  </p>
                </motion.div>

                <motion.div
                  className="navbar__top__left__item"
                  initial={{ opacity: 0, x: -20 }}
                  animate={{ opacity: 1, x: 0 }}
                  transition={{ duration: 0.4 }}
                >
                  <img
                    src="/assets/icons/water.svg"
                    alt="weather-icon"
                    style={{ filter: iconFilter }}
                  />
                  <p className="text-xs" style={{ color: textColor }}>
                    {temperature?.waterTemperature}°C
                  </p>
                </motion.div>
              </>
            )}
          </div>

          <div className="navbar__top__right">
            <div className="navbar__top__right__item campsite__status">
              <div
                className="campsite__status__icon"
                style={{
                  backgroundColor: campsiteStatus
                    ? "var(--alert)"
                    : "var(--green)",
                }}
              />
              <p
                className="text-xs"
                style={{
                  color: campsiteStatus ? "var(--alert)" : "var(--green)",
                }}
              >
                {campsiteStatus
                  ? t("campsite_status_closed", { ns: "header" })
                  : t("campsite_status_open", { ns: "header" })}
              </p>
            </div>

            <Link
              to="tel:+37126445124"
              className="navbar__top__right__item contact__number"
            >
              <img
                src="/assets/icons/phone.svg"
                alt="phone-icon"
                style={{ filter: iconFilter }}
              />
              <p className="text-xs" style={{ color: textColor }}>
                +371 26 445 124
              </p>
            </Link>

            <Link
              to="mailto:info@plaucaki.lv"
              className="navbar__top__right__item contact__email"
            >
              <img
                src="/assets/icons/mail.svg"
                alt="mail-icon"
                style={{ filter: iconFilter }}
              />
              <p className="text-xs" style={{ color: textColor }}>
                info@plaucaki.lv
              </p>
            </Link>

            <div
              className="navbar__top__right__item language__select"
              onClick={handleLanguageModal}
            >
              <img
                src="/assets/icons/world.svg"
                alt="world-icon"
                style={{ filter: iconFilter }}
              />
              <p
                className=".language__select__selected text-xs"
                style={{ color: textColor, textTransform: "uppercase" }}
              >
                {language}
              </p>
            </div>
          </div>
        </div>

        <div className="navbar__bottom container-center">
          <Link to="/" className="navbar__bottom__logo">
            <img src={imageToShow} alt="campsite-logo" />
          </Link>

          <nav className="navbar__bottom__navigation">
            <ul>
              {navigation?.map((item) => {
                const { id, name, link } = item;

                return (
                  <li key={id}>
                    <NavLink to={link} style={{ color: textColor }}>
                      {t(name, { ns: "navigation" })}
                    </NavLink>
                  </li>
                );
              })}
            </ul>
          </nav>

          <Link
            to="/reserve"
            className="navbar__bottom__btn button"
            style={{ opacity: containerScrolledPastHero ? 1 : 0 }}
          >
            {t("reserve", { ns: "header" })}
          </Link>

          <Hamburger
            className="navbar__bottom__mobile__btn"
            toggled={isOpen}
            toggle={setOpen}
            color={textColor}
          />
        </div>
      </div>

      <MobileNavigation
        ref={ref}
        initial="hidden"
        animate={isOpen ? "visible" : "hidden"}
        variants={variants}
      >
        <div className="mobile__container">
          <div className="mobile__container__top">
            <Link to="/" className="navbar__bottom__logo">
              <img src="/assets/small-icon.png" alt="campsite-logo" />
            </Link>

            <Hamburger
              className="mobile__container__top__btn"
              toggled={isOpen}
              toggle={setOpen}
              color={"var(--black)"}
            />
          </div>

          <div className="mobile__container__bottom">
            <div className="mobile__container__bottom__mobile__navigation">
              <ul>
                {navigation?.map((item) => {
                  const { id, name, link } = item;

                  return (
                    <li key={id} onClick={handleCloseNavigation}>
                      <NavLink
                        to={link}
                        style={{
                          color: "var(--green)",
                        }}
                      >
                        <p className="text-xl">
                          {t(name, { ns: "navigation" })}
                        </p>
                        <Arrow className="icon" />
                      </NavLink>
                    </li>
                  );
                })}
              </ul>

              <div className="mobile__container__bottom__mobile__navigation__contact">
                <Link to="tel:+37126445124" className="button">
                  <img src="/assets/icons/phone.svg" alt="phone-icon" />
                  <p className="text-lg">{t("call", { ns: "header" })}</p>
                </Link>
                <Link to="mailto:info@plaucaki.lv" className="button">
                  <img src="/assets/icons/mail.svg" alt="mail-icon" />
                  <p className="text-lg">{t("message", { ns: "header" })}</p>
                </Link>
              </div>

              <div className="mobile__container__bottom__mobile__navigation__reserve">
                <Link
                  to="/reserve"
                  className="button text-lg"
                  onClick={handleCloseNavigation}
                >
                  {t("reserve", { ns: "header" })}
                </Link>
              </div>
            </div>
          </div>
        </div>

        <div style={{ height: "150px" }} />
      </MobileNavigation>

      {isLanguageModal && (
        <>
          <LanguageModal
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            transition={{ duration: 0.4 }}
          >
            <div className="language__modal__container">
              <div className="language__modal__container__top">
                <h3 className="text-lg">Lapas valoda</h3>
                <Close className="icon" onClick={handleLanguageModal} />
              </div>

              <div className="language__modal__container__bottom">
                <ul>
                  {languageOptions?.map((item) => {
                    const { id, nameLat, nameCountry, active } = item;

                    return (
                      <li
                        key={id}
                        className={active ? "active" : undefined}
                        onClick={() => handleLanguageChange(id)}
                      >
                        <div>
                          <h3>{nameCountry}</h3>
                          <p>{nameLat}</p>
                        </div>

                        {active && (
                          <div>
                            <Checkmark className="icon" />
                          </div>
                        )}
                      </li>
                    );
                  })}
                </ul>
              </div>
            </div>
          </LanguageModal>
        </>
      )}
    </Wrapper>
  );
};

const LanguageModal = styled(motion.div)`
  max-width: 400px;
  min-width: 250px;
  width: 100% !important;
  position: absolute;
  top: 150px;
  left: 50%;
  transform: translateX(-50%);
  box-shadow: rgba(0, 0, 0, 0.05) 0px 6px 24px 0px,
    rgba(0, 0, 0, 0.08) 0px 0px 0px 1px;
  background-color: var(--white);
  border-radius: 8px;

  @media (max-width: 600px) {
    max-width: 90%;
  }

  .language__modal__container {
    padding: 20px;

    &__bottom {
      ul {
        display: flex;
        flex-direction: column;
        gap: 16px;

        li {
          border: 1px solid transparent;
          border-radius: 8px;
          padding: 10px 16px;
          cursor: pointer;
          display: flex;
          justify-content: space-between;
          align-items: flex-start;

          .icon {
            max-width: 32px;
            width: 100%;
            object-fit: contain;
          }
        }

        li:hover {
          background-color: var(--lightgrey);
        }

        .active {
          border-color: var(--black);
        }
      }
    }

    &__top {
      display: flex;
      justify-content: space-between;
      margin: 0 0 20px 0;

      .icon {
        max-width: 32px;
        width: 100%;
        object-fit: contain;
        padding: 3px;
        position: relative;
        bottom: 5px;
        left: 5px;
        cursor: pointer;
      }
    }
  }
`;

const MobileNavigation = styled(motion.div)`
  position: absolute;
  background-color: var(--white);
  width: 100%;
  height: 100vh;
  top: 0;
  left: 0;
  z-index: 110;
  transform: translateY(-100vh);
  overflow-y: scroll;

  .mobile__container {
    padding: 40px 20px 0;

    &__top {
      display: flex;
      align-items: center;
      justify-content: space-between;
      position: sticky;
      top: 0px;
      background-color: var(--white);
      z-index: 100;
      padding: 10px 0;
    }

    &__bottom {
      &__mobile__navigation {
        width: 100%;

        &__reserve {
          margin: 16px 0 0 0 !important;
          color: var(--white);
          font-weight: 600;

          a {
            justify-content: center;
          }
        }

        &__contact,
        &__reserve {
          display: flex;
          gap: 8px;
          margin: 20px 0 0 0;

          a {
            flex-grow: 1;
            display: flex;
            align-items: center;
            gap: 16px;

            p {
              color: var(--white);
              font-weight: 600;
            }

            img {
              max-width: 32px;
              max-height: 32px;
              width: 100%;
              object-fit: contain;
            }
          }
        }

        ul {
          display: flex;
          flex-direction: column;

          li {
            width: 100%;

            a {
              display: flex;
              justify-content: space-between;
              align-items: center;
              padding: 16px 0;

              .icon {
                max-width: 24px;
                width: 100%;
              }

              p {
                color: var(--green);
              }
            }
          }
        }
      }
    }
  }
`;

const Wrapper = styled.header`
  width: 100vw;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 100;
  transition: background-color 0.3s ease-in-out;

  .navbar {
    width: 100%;

    &__bottom {
      padding: 10px 20px;
      display: flex;
      justify-content: space-between;
      align-items: center;

      &__logo {
        img {
          max-width: 80px;
          min-width: 80px;
          width: 100%;
        }
      }

      &__navigation {
        @media screen and (max-width: 960px) {
          display: none;
        }

        ul {
          display: flex;

          li {
            a {
              white-space: nowrap;
              padding: 12px 16px;
              border-bottom: 3px solid transparent;
            }

            .active {
              border-bottom: 3px solid var(--green);
              color: var(--green) !important;
              font-weight: 500;
            }

            a:hover {
              color: var(--green) !important;
            }
          }
        }
      }

      &__btn {
        @media screen and (max-width: 960px) {
          display: none;
        }
      }

      .hamburger-react {
        height: 50px !important;
        display: none;

        @media screen and (max-width: 960px) {
          display: block;
        }
      }
    }

    &__top {
      display: flex;
      justify-content: space-between;
      padding: 8px 20px;

      &__left,
      &__right {
        display: flex;
        align-items: center;
        gap: 16px;

        .language__select {
          cursor: pointer;
        }

        .language__select:hover {
          opacity: 0.7;
        }

        .campsite__status {
          &__icon {
            width: 10px;
            height: 10px;
            border-radius: 50%;
          }

          p {
            font-weight: 600;
          }
        }

        &__item {
          display: flex;
          align-items: center;
          gap: 8px;

          img {
            max-width: 18px;
            min-width: 18px;
            width: 100%;
          }
        }
      }

      &__right {
        .contact__number,
        .contact__email {
          @media screen and (max-width: 700px) {
            display: none;
          }
        }
      }
    }
  }
`;

export default Header;
