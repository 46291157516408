import React from "react";
import styled from "styled-components";

const LastBookings = ({ name, Icon, data }) => {
  return (
    <Wrapper>
      <WidgetTop>
        <div>
          <h4 className='text-lg'>{name}</h4>
        </div>

        <div className="icon-container">{<Icon className="icon" />}</div>
      </WidgetTop>

      <WidgetContent>
        {data?.map((customer) => {
          const {
            booking_id,
            status,
            name,
            email,
            total_price,
            accommodation_id,
            check_in_date,
          } = customer;

          let iconBackgroundColor = "";
          let textColor = "";
          let statusTranslated = "";
          let accommodationName = "";

          if (status === "Pending") {
            iconBackgroundColor = "var(--grey)";
            textColor = "var(--black)";
            statusTranslated = "Neapstiprināts";
          } else if (status === "Confirmed") {
            iconBackgroundColor = "var(--blue)";
            textColor = "var(--white)";
            statusTranslated = "Apstiprināts";
          } else {
            iconBackgroundColor = "var(--green)";
            textColor = "var(--white)";
            statusTranslated = "Pabeigts";
          }

          if (accommodation_id <= 12) {
            accommodationName = `${accommodation_id}. mājiņa`;
          } else if (accommodation_id === 13) {
            accommodationName = `Telts vieta`;
          } else {
            accommodationName = `Treilera vieta`;
          }

          return (
            <Customer key={booking_id}>
              <div className="customer__booking__status">
                <p
                  className="text-sm"
                  style={{
                    backgroundColor: iconBackgroundColor,
                    color: textColor,
                    fontWeight: 500,
                  }}
                >
                  {statusTranslated}
                </p>
              </div>

              <div className="customer__data">
                <div className="customer__data__avatar">{name[0]}</div>
                <div>
                  <p className="customer__data__name">{name}</p>
                  <p className="customer__data__email text-sm">{email}</p>
                </div>
              </div>

              <div className="customer__booking">
                <p className="customer__booking__price">€{total_price}</p>
                <p className="customer__booking__start-date">{check_in_date}</p>
              </div>

              <div className="customer__booked__place text-sm">
                <p>{accommodationName}</p>
              </div>
            </Customer>
          );
        })}
      </WidgetContent>
    </Wrapper>
  );
};

const Customer = styled.div`
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-gap: 16px;
  min-width: 650px;

  .customer__booking__status {
    p {
      padding: 3px 6px;
      border-radius: 8px;
      display: inline-block;
    }
  }

  .customer__booking {
    &__price {
      font-weight: 500;
    }

    &__start-date {
      color: var(--grey);
    }
  }

  .customer__data {
    display: flex;
    gap: 8px;

    &__avatar {
      height: 32px;
      width: 32px;
      border-radius: 50%;
      background-color: var(--green);
      display: flex;
      align-items: center;
      justify-content: center;
      font-weight: bold;
      color: white;
      text-transform: uppercase;
    }

    &__name {
      font-weight: 500;
    }

    &__email {
      color: var(--grey);
    }

    &__name,
    &__email {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      width: 200px;
    }
  }
`;

const WidgetContent = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
  padding: 10px;

  @media (max-width: 1074px){
    overflow-x: scroll;
  }
`;

const WidgetTop = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  gap: 24px;
  flex-wrap: wrap;
  margin: 0 0 16px 0;

  .icon-container {
    padding: 5px 8px 8px;
    background-color: var(--green);
    border-radius: 8px;

    .icon {
      min-width: 18px;
      max-width: 18px;
      width: 100%;
      color: var(--white);
    }
  }
`;

const Wrapper = styled.div`
  box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 3px 0px,
    rgba(27, 31, 35, 0.15) 0px 0px 0px 1px;
  padding: 16px;
  border-radius: 8px;
`;

export default LastBookings;
