import React from "react";
import styled from "styled-components";

const Widget = ({ name, value, Icon }) => {
  return (
    <Wrapper>
      <WidgetTop>
        <h4>{name}</h4>

        <div className="icon-container">{<Icon className="icon" />}</div>
      </WidgetTop>

      <WidgetBottom>
        <p className="text-3xl">{value}</p>
      </WidgetBottom>
    </Wrapper>
  );
};

const WidgetBottom = styled.div`
  p {
    font-weight: 700;
    color: var(--green);
  }
`;

const WidgetTop = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  gap: 24px;
  margin: 0 0 16px 0;

  .icon-container {
    padding: 5px 8px 8px;
    background-color: var(--green);
    border-radius: 8px;

    .icon {
      min-width: 18px;
      max-width: 18px;
      width: 100%;
      color: var(--white);
    }
  }
`;

const Wrapper = styled.div`
  box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 3px 0px,
    rgba(27, 31, 35, 0.15) 0px 0px 0px 1px;
  padding: 16px;
  border-radius: 8px;

  @media (max-width: 920px) {
    min-width: 200px;
  }
`;

export default Widget;
