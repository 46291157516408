import React from "react";
import { Link } from "react-router-dom";
import { motion } from "framer-motion";
import styled from "styled-components";

//locales
import { useTranslation } from "react-i18next";

const AccommodationCard = ({ data }) => {
  const { t } = useTranslation();
  const { name, desc, price, link, placeholder } = data;

  return (
    <Wrapper
      layout
      initial={{ opacity: 0, y: 20 }}
      animate={{ opacity: 1, y: 0 }}
      exit={{ opacity: 0, y: -20 }}
      transition={{ duration: 0.4 }}
      className="house__container__houses__house"
    >
      <div className="image">
        <Link to={link}>
          <img src={placeholder} alt="house-image" />
        </Link>
      </div>
      <div className="data">
        <div className="data__top">
          <h2 className="text-xl">{name}</h2>
          <p>{t(desc, { ns: "accommodations" })}</p>
        </div>
        <div className="data__bottom">
          <div className="price">
            <span className="text-lg">€{price} /</span>{""}{t("accommodation_card_night", { ns: "accommodations" })}
          </div>

          <Link to={link} className="button">
            {t("reserve", { ns: "header" })}
          </Link>
        </div>
      </div>
    </Wrapper>
  );
};

const Wrapper = styled(motion.div)`
  width: 100%;
  display: flex;
  flex-direction: column;

  .image {
    max-width: 100%;
    max-height: 250px;
    height: 250px;
    background-color: var(--lightgrey);
    width: 100%;
    height: 100%;
    box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 12px;

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      border-radius: 8px;
    }
  }

  .data {
    margin: 16px 0;

    &__top {
      p {
        margin: 16px 0 24px;
      }
    }

    &__bottom {
      display: flex;
      align-items: center;
      justify-content: space-between;

      span {
        font-weight: 600;
      }
    }
  }
`;

export default AccommodationCard;
