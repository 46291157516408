import React from "react";
import { Link } from "react-router-dom";
import { motion } from "framer-motion";
import styled from "styled-components";

//locales
import { useTranslation } from "react-i18next";

const AccommodationCard = ({ data, reservationData }) => {
  const { t } = useTranslation();
  const { name, desc, price, link, images } = data;
  const { startDate, endDate, totalNights, totalPrice } = reservationData;

  return (
    <Wrapper
      layout
      initial={{ opacity: 0, y: 20 }}
      animate={{ opacity: 1, y: 0 }}
      exit={{ opacity: 0, y: -20 }}
      transition={{ duration: 0.4 }}
      className="house__container__houses__house"
    >
      <div className="image">
        <Link to={link}>
          <img src={images[0]} alt="house-image" />
        </Link>

        <div className='reservation-data-dates'>
          <p className="text-xs">{startDate} - {endDate}</p>
        </div>
      </div>
      <div className="data">
        <div className="data__top">
          <h2 className="text-xl">{name}</h2>
          <p>{t(desc, { ns: "accommodations" })}</p>
        </div>
        <div className="data__bottom">
          <div className="price" style={{marginBottom: 8}}>
            <span className="text-lg">€{price} /</span>{t("accommodation_card_night", { ns: "accommodations" })}
          </div>
          <div className="price">
            <span className="text-lg">€{totalPrice} /</span> {totalNights} {t("accommodation_card_nights", { ns: "accommodations" })}
          </div>
        </div>
      </div>
    </Wrapper>
  );
};

const Wrapper = styled(motion.div)`
  width: 100%;
  display: flex;
  flex-direction: column;

  .image {
    max-width: 100%;
    height: 200px;
    background-color: var(--lightgrey);
    width: 100%;
    position: relative;
    box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 12px;

    .reservation-data-dates {
      position: absolute;
      top: 15px;
      right: 15px;
      background-color: var(--white);
      padding: 6px 8px;
      border-radius: 8px;
    }

    a {
      height: 100%;
    }

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      border-radius: 8px;
    }
  }

  .data {
    margin: 16px 0;

    &__top {
      p {
        margin: 16px 0 24px;
      }
    }

    &__bottom {
      display: flex;
      flex-direction: column;

      span {
        font-weight: 600;
      }
    }
  }
`;

export default AccommodationCard;
