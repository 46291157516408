import React from "react";
import styled from "styled-components";

const MostCreatedReservations = ({ name, desc, Icon, data }) => {
  return (
    <Wrapper>
      <WidgetTop>
        <div>
          <h4 className='text-lg'>{name}</h4>
          <p className="text-sm">{desc}</p>
        </div>

        <div className="icon-container">{<Icon className="icon" />}</div>
      </WidgetTop>

      <WidgetContent>
        {data?.map((customer) => {
          const { name, email, booking_count } = customer;

          return (
            <Customer key={email}>
              <div className="customer__avatar">{name?.[0]}</div>

              <div className="customer__data">
                <p className="customer__data__name">{name} ({booking_count})</p>
                <p className="customer__data__email text-sm">{email}</p>
              </div>
            </Customer>
          );
        })}
      </WidgetContent>
    </Wrapper>
  );
};

const Customer = styled.div`
  display: flex;
  gap: 16px;

  .customer__data {
    &__name {
      font-weight: 500;
    }

    &__email {
      color: var(--grey);
    }

    &__name,
    &__email {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      max-width: 200px;
    }
  }

  .customer__avatar {
    height: 32px;
    width: 32px;
    border-radius: 50%;
    background-color: var(--green);
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: bold;
    color: white;
    text-transform: uppercase;
  }
`;

const WidgetContent = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
`;

const WidgetTop = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  gap: 24px;
  margin: 0 0 16px 0;

  p {
    color: var(--grey);
  }

  .icon-container {
    padding: 5px 8px 8px;
    background-color: var(--green);
    border-radius: 8px;

    .icon {
      min-width: 18px;
      max-width: 18px;
      width: 100%;
      color: var(--white);
    }
  }
`;

const Wrapper = styled.div`
  box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 3px 0px,
    rgba(27, 31, 35, 0.15) 0px 0px 0px 1px;
  padding: 16px;
  border-radius: 8px;
`;

export default MostCreatedReservations;
