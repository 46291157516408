import React from "react";
import styled from "styled-components";
import { Link } from "react-router-dom";

//icons
import { ArrowCircleRight as Departure } from "@styled-icons/fluentui-system-regular/ArrowCircleRight";
import { ArrowCircleLeft as Arrival } from "@styled-icons/fluentui-system-regular/ArrowCircleLeft";
import { Person } from "@styled-icons/bootstrap/Person";
import { House } from "@styled-icons/bootstrap/House";
import { PhoneCall as Phone } from "@styled-icons/feather/PhoneCall";
import { Tent } from "@styled-icons/fluentui-system-filled/Tent";
import { Caravan } from "@styled-icons/fa-solid/Caravan";

const CalendarWidgets = ({ data }) => {
  const { arrivals, departures, stayover } = data;

  const Widget = ({ name, Icon, data }) => {
    return (
      <WidgetContainer>
        <WidgetTop>
          <div>
            <h4 className="text-lg">
              {name} ({data?.length})
            </h4>
          </div>

          <div className="icon-container">{<Icon className="icon" />}</div>
        </WidgetTop>

        <WidgetContent>
          {data?.length === 0 && <p style={{textAlign: 'center', color: 'var(--grey)'}}>Nav atrastu rezultātu</p>}
          {data?.length > 0 &&
            data?.map((reservation) => {
              let reservedPlace = "";
              if (reservation?.accommodation_id <= 12) {
                reservedPlace = `${reservation?.accommodation_id}. mājiņa`;
              } else if (reservation?.accommodation_id === 13) {
                reservedPlace = "Telts vieta";
              } else {
                reservedPlace = "Treilera vieta";
              }

              const calculateStayDuration = (checkIn, checkOut) => {
                const checkInDate = new Date(checkIn);
                const checkOutDate = new Date(checkOut);

                const differenceInMilliseconds = checkOutDate - checkInDate;

                const differenceInDays =
                  differenceInMilliseconds / (1000 * 60 * 60 * 24);

                return `${differenceInDays} nakts/-is`;
              };

              return (
                <div className="reservation" key={reservation?.booking_id}>
                  <div className="reservation__top">
                    <div className="reservation__top__left">
                      <div className="reservation__top__left__icon">
                        {reservation?.accommodation_id <= 12 && (
                          <House className="icon" />
                        )}
                        {reservation?.accommodation_id === 13 && (
                          <Tent className="icon" />
                        )}
                        {reservation?.accommodation_id === 14 && (
                          <Caravan className="icon" />
                        )}
                      </div>

                      <div className="reservation__top__left__data">
                        <h3>{reservation?.name}</h3>
                        <p className="text-sm">
                          {reservedPlace},{" "}
                          {calculateStayDuration(
                            reservation?.check_in_date,
                            reservation?.check_out_date
                          )}
                        </p>
                      </div>
                    </div>

                    <div className="reservation__top__contact">
                      <Link to={`tel:${reservation?.phone_number}`}>
                        <Phone className="icon" />
                      </Link>
                    </div>
                  </div>

                  {reservation?.notes?.length > 0 && (
                    <>
                      <div className="reservation__comment">
                        <p className='text-sm'>"{reservation?.notes}"</p>
                      </div>
                    </>
                  )}
                </div>
              );
            })}
        </WidgetContent>
      </WidgetContainer>
    );
  };

  return (
    <Wrapper>
      <Widget name={"Iebrauc"} Icon={Arrival} data={arrivals} />
      <Widget name={"Izbrauc"} Icon={Departure} data={departures} />
      <Widget name={"Aktīvās rezervācijas"} Icon={Person} data={stayover} />
    </Wrapper>
  );
};

const WidgetContent = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
  max-height: 350px;
  overflow-y: scroll;
  -ms-overflow-style: none;
  scrollbar-width: none;

  ::-webkit-scrollbar {
    display: none;
  }

  .reservation:nth-last-child(1) {
    border-bottom: none;
  }

  .reservation {
    display: flex;
    flex-direction: column;
    gap: 8px;
    border-bottom: 1px solid var(--lightgrey);
    padding: 0 0 16px 0;

    &__comment {
      font-style: italic;
    }

    &__top {
      display: flex;
      justify-content: space-between;
      align-items: flex-start;

      &__contact {
        a {
          background-color: var(--green);
          width: 32px;
          height: 32px;
          border-radius: 8px;
          display: flex;
          justify-content: center;
          align-items: center;

          .icon {
            min-width: 18px;
            max-width: 18px;
            width: 100%;
            color: var(--white);
          }
        }

        a:hover {
          background-color: var(--darkgreen);
        }
      }

      &__left {
        display: flex;
        gap: 8px;

        &__data {
          h3 {
            max-width: 180px;
            text-overflow: ellipsis;
            white-space: nowrap;
            overflow: hidden;
          }
        }

        &__icon {
          background-color: var(--green);
          width: 32px;
          height: 32px;
          border-radius: 8px;
          display: flex;
          justify-content: center;
          align-items: center;

          .icon {
            min-width: 18px;
            max-width: 18px;
            width: 100%;
            color: var(--white);
          }
        }
      }
    }
  }
`;

const WidgetTop = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  gap: 24px;
  margin: 0 0 32px 0;

  p {
    color: var(--grey);
  }

  .icon-container {
    padding: 5px 8px 8px;
    background-color: var(--green);
    border-radius: 8px;

    .icon {
      min-width: 18px;
      max-width: 18px;
      width: 100%;
      color: var(--white);
    }
  }
`;

const WidgetContainer = styled.div`
  box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 3px 0px,
    rgba(27, 31, 35, 0.15) 0px 0px 0px 1px;
  padding: 16px;
  border-radius: 8px;

  @media (max-width: 1160px) {
    min-width: 300px;
  }
`;

const Wrapper = styled.section`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 16px;
  margin: 0 0 16px 0;

  @media (max-width: 1160px) {
    padding: 10px;
    overflow-x: scroll;
  }
`;

export default CalendarWidgets;
