export const policy = [
  {
    heading: "policy_head1",
    list: ["policy_1_p1", "policy_1_p2", "policy_1_p3", "policy_1_p4"],
  },
  {
    heading: "policy_head2",
    list: ["policy_2_p1", "policy_2_p2", "policy_2_p3", "policy_2_p4"],
  },
  {
    heading: "policy_head3",
    list: ["policy_3_p1", "policy_3_p2", "policy_3_p3"],
  },
  {
    heading: "policy_head4",
    list: [],
  },
  {
    heading: "policy_head5",
    list: [],
  },
  {
    heading: "policy_head6",
    list: ["policy_6_p1", "policy_6_p2", "policy_6_p3"],
  },
  {
    heading: "policy_head7",
    list: [],
  },
  {
    heading: "policy_head8",
    list: [],
  },
];
