import { priceList } from "./pricingData";

function createHouse(id, desc, type, persons, price) {
  return {
    id,
    name: `${id}. mājiņa`,
    type,
    desc,
    persons,
    price,
    link: `/houses/${id}`,
    images: [`/assets/houses/${id}/4.png`, `/assets/houses/${id}/5.png`, `/assets/houses/${id}/6.png`],
    placeholder: `/assets/houses/${id}/placeholder-${id}.png`,
  };
}

function createTentOrTrailer(id, desc, type, persons, price) {
  return {
    id,
    name: type === "tent" ? `Telts vieta` : `Treilera vieta`,
    type,
    desc,
    persons,
    price,
    link: type === "tent" ? "/tents" : "/trailers",
    images:
      type === "tent"
        ? [`/assets/tents/0.png`, `/assets/tents/1.png`]
        : [`/assets/trailer/0.png`, `/assets/trailer/1.png`],
    placeholder:
      type === "tent"
        ? `/assets/tents/placeholder-${id}.png`
        : `/assets/trailer/placeholder-${id}.png`,
  };
}

const accommodationData = [
  createHouse(
    1,
    "accommodation_1_desc",
    "house",
    4,
    priceList?.fourBedroomHouse
  ),
  createHouse(
    2,
    "accommodation_2_desc",
    "house",
    2,
    priceList?.twoBedroomHouse
  ),
  createHouse(
    3,
    "accommodation_3_desc",
    "house",
    2,
    priceList?.twoBedroomHouse
  ),
  createHouse(
    4,
    "accommodation_4_desc",
    "house",
    2,
    priceList?.twoBedroomHouse
  ),
  createHouse(
    5,
    "accommodation_5_desc",
    "house",
    2,
    priceList?.twoBedroomHouse
  ),
  createHouse(
    6,
    "accommodation_6_desc",
    "house",
    3,
    priceList?.threeBedroomHouse
  ),
  createHouse(
    7,
    "accommodation_7_desc",
    "house",
    3,
    priceList?.threeBedroomHouse
  ),
  createHouse(
    8,
    "accommodation_8_desc",
    "house",
    3,
    priceList?.threeBedroomHouse
  ),
  createHouse(
    9,
    "accommodation_9_desc",
    "house",
    2,
    priceList?.twoBedroomHouse
  ),
  createHouse(
    10,
    "accommodation_10_desc",
    "house",
    6,
    priceList?.sixBedroomHouse
  ),
  createHouse(
    11,
    "accommodation_11_desc",
    "house",
    2,
    priceList?.twoBedroomHouse
  ),
  createHouse(
    12,
    "accommodation_12_desc",
    "house",
    2,
    priceList?.twoBedroomHouse
  ),
  createTentOrTrailer(13, "accommodation_13_desc", "tent", 5, priceList?.tent),
  createTentOrTrailer(
    14,
    "accommodation_14_desc",
    "trailer",
    5,
    priceList?.trailer
  ),
];

const accommodationHouseInformation = {
  accommodations: accommodationData.filter(
    (accommodation) => accommodation.type === "house"
  ),
  description: [
    "accommodation_desc_1",
    "accommodation_desc_2",
    "accommodation_desc_3",
    "accommodation_desc_4",
    "accommodation_desc_5",
  ],
  amenities: [
    {
      img: "/assets/amenities-icons/beds.svg",
      name: "accommodation_amenities_beds",
    },
    {
      img: "/assets/amenities-icons/eletricity.svg",
      name: "accommodation_amenities_electricity",
    },
    {
      img: "/assets/amenities-icons/shower.svg",
      name: "accommodation_amenities_shower",
      desc: "accommodation_amenities_shower_desc",
    },
    {
      img: "/assets/amenities-icons/water.svg",
      name: "accommodation_amenities_water",
    },
    {
      img: "/assets/amenities-icons/handwash.svg",
      name: "accommodation_amenities_dishes",
    },
    {
      img: "/assets/amenities-icons/wood.svg",
      name: "accommodation_amenities_wood",
      desc: "accommodation_amenities_wood_desc",
    },
    {
      img: "/assets/amenities-icons/grill.svg",
      name: "accommodation_amenities_grill",
    },
    {
      img: "/assets/amenities-icons/grill-accessory.svg",
      name: "accommodation_amenities_grill_tools",
    },
    {
      img: "/assets/amenities-icons/beach.svg",
      name: "accommodation_amenities_from_sea",
    },
    {
      img: "/assets/amenities-icons/dog.svg",
      name: "accommodation_amenities_pet_friendly",
    },
  ],
};

const accommodationTentInformation = {
  id: 13,
  name: "Telts vieta",
  desc: "Telts vieta",
  persons: 5,
  price: priceList?.tent,
  images: ["/assets/tents/0.png", "/assets/tents/1.png"],
  bookedDates: [],
  amenities: [
    {
      img: "/assets/amenities-icons/eletricity.svg",
      name: "accommodation_amenities_electricity",
      desc: "accommodation_amenities_electricity_desc",
    },
    {
      img: "/assets/amenities-icons/shower.svg",
      name: "accommodation_amenities_shower",
      desc: "accommodation_amenities_shower_desc",
    },
    {
      img: "/assets/amenities-icons/water.svg",
      name: "accommodation_amenities_water",
    },
    {
      img: "/assets/amenities-icons/handwash.svg",
      name: "accommodation_amenities_dishes",
    },
    {
      img: "/assets/amenities-icons/wood.svg",
      name: "accommodation_amenities_wood",
      desc: "accommodation_amenities_wood_desc",
    },
    {
      img: "/assets/amenities-icons/grill.svg",
      name: "accommodation_amenities_grill",
    },
    {
      img: "/assets/amenities-icons/grill-accessory.svg",
      name: "accommodation_amenities_grill_tools",
    },
    {
      img: "/assets/amenities-icons/beach.svg",
      name: "accommodation_amenities_from_sea",
    },
    {
      img: "/assets/amenities-icons/dog.svg",
      name: "accommodation_amenities_pet_friendly",
    },
  ],
  description: [
    "accommodation_desc_6",
    "accommodation_desc_7",
    "accommodation_desc_2",
    "accommodation_desc_3",
    "accommodation_desc_4",
    "accommodation_desc_5",
  ],
};

const accommodationTrailerInformation = {
  id: 14,
  name: "Treilera vieta",
  desc: "Treilera vieta",
  persons: 5,
  price: priceList?.trailer,
  images: ["/assets/trailer/0.png", "/assets/trailer/1.png"],
  bookedDates: [],
  amenities: [
    {
      img: "/assets/amenities-icons/eletricity.svg",
      name: "accommodation_amenities_electricity",
      desc: "accommodation_amenities_electricity_desc",
    },
    {
      img: "/assets/amenities-icons/shower.svg",
      name: "accommodation_amenities_shower",
      desc: "accommodation_amenities_shower_desc",
    },
    {
      img: "/assets/amenities-icons/water.svg",
      name: "accommodation_amenities_water",
    },
    {
      img: "/assets/amenities-icons/handwash.svg",
      name: "accommodation_amenities_dishes",
    },
    {
      img: "/assets/amenities-icons/handwash.svg",
      name: "accommodation_amenities_sanitary",
    },
    {
      img: "/assets/amenities-icons/wood.svg",
      name: "accommodation_amenities_wood",
      desc: "accommodation_amenities_wood_desc",
    },
    {
      img: "/assets/amenities-icons/grill.svg",
      name: "accommodation_amenities_grill",
    },
    {
      img: "/assets/amenities-icons/grill-accessory.svg",
      name: "accommodation_amenities_grill_tools",
    },
    {
      img: "/assets/amenities-icons/beach.svg",
      name: "accommodation_amenities_from_sea",
    },
    {
      img: "/assets/amenities-icons/dog.svg",
      name: "accommodation_amenities_pet_friendly",
    },
  ],
  description: [
    "accommodation_desc_8",
    "accommodation_desc_9",
    "accommodation_desc_2",
    "accommodation_desc_3",
    "accommodation_desc_4",
    "accommodation_desc_5",
  ],
};

export {
  accommodationData,
  accommodationHouseInformation,
  accommodationTentInformation,
  accommodationTrailerInformation,
};
