import React from "react";
import styled from "styled-components";

const ReservationPercentage = ({ name, Icon, barData }) => {
  return (
    <Wrapper>
      <WidgetTop>
        <h4>{name}</h4>

        <div className="icon-container">{<Icon className="icon" />}</div>
      </WidgetTop>

      <WidgetContent>
        {barData?.map((bar, i) => {
          const { name, percentage } = bar;

          return (
            <Bar key={i}>
              <div className="bar__top">
                <p className="bar__top__name">{name}</p>

                <p className="bar__top__percentage text-sm">{`${percentage}%`}</p>
              </div>
              <div className="bar__container">
                <div
                  className="bar__container__percentage"
                  style={{ width: `${percentage}%` }}
                />
              </div>
            </Bar>
          );
        })}
      </WidgetContent>
    </Wrapper>
  );
};

const Bar = styled.div`
  .bar__container {
    width: 100%;
    height: 4px;
    background-color: var(--lightgrey);

    &__percentage {
      background-color: var(--green);
      height: 100%;
    }
  }

  .bar__top {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: 0 0 8px 0;
  }
`;

const WidgetContent = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
`;

const WidgetTop = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  gap: 24px;
  margin: 0 0 16px 0;
  flex-wrap: wrap;

  .icon-container {
    padding: 5px 8px 8px;
    background-color: var(--green);
    border-radius: 8px;

    .icon {
      min-width: 18px;
      max-width: 18px;
      width: 100%;
      color: var(--white);
    }
  }
`;

const Wrapper = styled.div`
  box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 3px 0px,
    rgba(27, 31, 35, 0.15) 0px 0px 0px 1px;
  padding: 16px;
  border-radius: 8px;
`;

export default ReservationPercentage;
