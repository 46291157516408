import React, { useRef } from "react";
import styled from "styled-components";
import { Link } from "react-router-dom";
import { motion, useInView } from "framer-motion";

//locales
import { useTranslation } from "react-i18next";

const Accommodations = () => {
  const { t } = useTranslation();
  const ref = useRef(null);
  const isInView = useInView(ref, { once: true });

  const variants = {
    hidden: { opacity: 0, y: 50 },
    visible: { opacity: 1, y: 0, transition: { duration: 0.6 } },
  };

  const AccommodationItem = ({ data }) => {
    const { img, name, desc, link, flexReverse } = data;

    return (
      <motion.div
        className="accommodations__content__accommodation"
        style={{ flexDirection: flexReverse ? "row-reverse" : "row" }}
      >
        <div className="accommodations__content__accommodation__image">
          <img src={img} alt="accommodation-image" />
        </div>

        <div className="accommodations__content__accommodation__data">
          <h3 className="text-3xl">{t(name, { ns: "home" })}</h3>
          <div className="accommodations__content__accommodation__data__description">
            {desc?.map((item, i) => (
              <p key={i}>{t(item, { ns: "home" })}</p>
            ))}
          </div>

          <div>
            <Link className="button" to={link}>
              {t("accommodations_learn_more", { ns: "home" })}
            </Link>
          </div>
        </div>
      </motion.div>
    );
  };

  return (
    <Wrapper
      id="accommodations"
      ref={ref}
      initial="hidden"
      animate={isInView ? "visible" : "hidden"}
      variants={variants}
    >
      <div className="accommodations container-center">
        <div className="accommodations__header">
          <div className="accommodations__header__left">
            <h4>{t("accommodations_offer", { ns: "home" })}</h4>
            <h2 className="text-3xl">
              {t("accommodations_offer_desc", { ns: "home" })}
            </h2>
          </div>

          <div className="accommodations__header__right">
            <p>{t("accommodations_quote", { ns: "home" })}</p>
          </div>
        </div>

        <div className="accommodations__content">
          <AccommodationItem
            data={{
              img: "/assets/home/house.png",
              name: "accommodation1_name",
              desc: [
                "accommodation1_desc1",
                "accommodation1_desc2",
              ],
              link: "/houses",
            }}
          />

          <AccommodationItem
            data={{
              img: "/assets/home/tent.png",
              name: "accommodation2_name",
              desc: [
                "accommodation2_desc1",
                "accommodation2_desc2",
              ],
              link: "/tents",
              flexReverse: true,
            }}
          />

          <AccommodationItem
            data={{
              img: "/assets/home/trailer.png",
              name: "accommodation3_name",
              desc: [
                "accommodation3_desc1",
                "accommodation3_desc2",
              ],
              link: "/trailers",
            }}
          />
        </div>
      </div>
    </Wrapper>
  );
};

const Wrapper = styled(motion.section)`
  .accommodations {
    &__content {
      padding: 60px 0;
      display: flex;
      flex-direction: column;
      gap: 40px;

      &__accommodation {
        display: flex;
        align-items: center;

        @media screen and (max-width: 800px) {
          flex-direction: column !important;

          &__data {
            padding: 32px 10px 24px !important;
          }

          &__image {
            max-width: 100% !important;
            height: 400px;

            img {
              height: 100%;
              object-fit: cover;
            }
          }
        }

        &__data {
          padding: 0 60px;
          display: flex;
          flex-direction: column;
          gap: 16px;

          &__description {
            display: flex;
            flex-direction: column;
            gap: 24px;
            margin: 0 0 32px;
          }
        }

        &__image {
          max-width: 560px;
          min-width: 300px;
          width: 100%;
          object-fit: contain;

          img {
            border-radius: 8px;
            width: 100%;
          }
        }
      }
    }

    &__header {
      display: flex;
      justify-content: space-between;
      align-items: flex-start;
      gap: 24px;

      @media screen and (max-width: 780px) {
        flex-direction: column;

        &__right {
          max-width: unset !important;
        }
      }

      &__left {
        h4 {
          margin: 0 0 8px 0;
          color: var(--grey);
          font-weight: 400;
        }

        h2 {
          max-width: 300px;
          background: -webkit-linear-gradient(var(--green), var(--darkgreen));
          -webkit-background-clip: text;
          -webkit-text-fill-color: transparent;
          font-weight: 700;
        }
      }

      &__right {
        max-width: 400px;

        p {
          color: var(--green);
          line-height: 1.5rem;
        }
      }
    }
  }
`;

export default Accommodations;
