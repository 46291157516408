import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { useNavigate } from "react-router-dom";

//components
import Alert from "../../components/Alert";

//redux
import { useDispatch, useSelector } from "react-redux";
import { login, selectCurrentUser } from "../../redux/authState";

const Login = () => {
  const [alertText, setAlertText] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [disableSubmit, setDisableSubmit] = useState(true);

  const currentUser = useSelector(selectCurrentUser);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setAlertText("");

    try {
      await dispatch(login({ email, password })).unwrap();
      navigate("/admin/dashboard");
    } catch (err) {
      setAlertText(err);
    }
  };

  useEffect(() => {
    const isInputValid = (input) => input.trim().length > 8;
    const isPasswordValid = isInputValid(password);

    setDisableSubmit(!isPasswordValid);
  }, [email, password]);

  useEffect(() => {
    if (currentUser) {
      navigate("/admin/dashboard");
    }
  }, [currentUser, navigate]);

  return (
    <Wrapper>
      <Auth>
        <div className="auth-content">
          <img src="/assets/big-icon.png" alt="auth-logo" />

          <div className="welcome-message">
            <h2 className="text-lg">Hei! Sveicināts atpakaļ!</h2>
            <p className="text-sm">Ievadi detaļas lai turpinātu</p>
          </div>

          <form onSubmit={handleSubmit}>
            {alertText && <Alert type="error" text={alertText} />}
            <div className="form__elem">
              <label htmlFor="email">E-pasts</label>

              <div className="form__elem__input">
                <input
                  id="email"
                  type="email"
                  name="email"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  maxLength={112}
                  required
                />
              </div>
            </div>

            <div className="form__elem">
              <label htmlFor="password">Parole</label>

              <div className="form__elem__input">
                <input
                  id="password"
                  type="password"
                  name="password"
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                  maxLength={112}
                  required
                />
              </div>
            </div>

            <div className="form__elem">
              <button
                disabled={disableSubmit}
                style={{
                  backgroundColor: disableSubmit && "var(--grey)",
                }}
                type="submit"
                className="submit-button"
              >
                Ienākt
              </button>
            </div>
          </form>
        </div>
      </Auth>

      <LoginImage>
        <img src="/assets/gallery/0.jpg" alt="auth-image" />
      </LoginImage>
    </Wrapper>
  );
};

const LoginImage = styled.section`
  height: 100vh;
  max-width: 900px;
  width: 100%;
  overflow: hidden;
  position: relative;

  @media (max-width: 760px) {
    position: absolute;
    max-width: 100vw;
    min-width: 100vw;
  }

  img {
    height: 100%;
    width: 100%;
    object-fit: cover;
    filter: brightness(0.9) contrast(1.1);
  }
`;

const Auth = styled.section`
  flex-grow: 1;
  width: 100%;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 0 0 20px;
  min-width: 300px;
  z-index: 100;

  @media (max-width: 760px) {
    margin: 0;

    .auth-content {
      width: 100%;
      max-width: 80% !important;
    }
  }

  .auth-content {
    max-width: 400px;
    width: 100%;
    background: var(--white);
    padding: 16px;
    border-radius: 8px;
  }

  form {
    display: flex;
    flex-direction: column;
    gap: 16px;

    .form__elem {
      width: 100%;
    }

    .submit-button {
      padding: 8px 24px;
      background: var(--green);
      color: var(--white);
      border-radius: 8px;
      transition: 0.3s ease-in-out;
      width: 100%;
      font-size: 1rem;
      cursor: pointer;
    }

    input {
      min-width: 100%;
      padding: 8px 16px;
      border: 1px solid var(--grey);
      outline: none;
      border-radius: 8px;
      margin: 8px 0 0 0;
    }

    label {
      color: var(--green);
      font-weight: 600;
      font-size: 1rem;
    }

    .submit-button:disabled {
      background-color: var(--grey);
      cursor: not-allowed;
    }
  }

  .welcome-message {
    margin: 16px 0 32px;
  }

  img {
    max-width: 90px;
    width: 100%;
    object-fit: contain;
  }
`;

const Wrapper = styled.section`
  display: flex;
  gap: 24px;
  width: 100vw;
  max-height: 100vh;
  height: 100%;
  justify-content: space-between;
  overflow-y: hidden;
`;

export default Login;
