const requireImages = require.context('../assets/gallery', false, /\.(jpg|jpeg|png|webp)$/);

export const images = requireImages.keys().map((fileName, index) => {
  const original = requireImages(fileName).default || requireImages(fileName);
  return {
    src: original,
    original,
    width: index % 2 === 0 ? 320 : 420, 
    height: 213, 
  };
});
