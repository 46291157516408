import React, { useEffect, useState } from "react";
import styled from "styled-components";

//components
import SectionHero from "../components/SectionHero";
import AccommodationCard from "../components/houses/AccommodationCard";
import Skeleton from "react-loading-skeleton";

import { accommodationHouseInformation } from "../data/accommodationData";

//helmet
import { Helmet } from "react-helmet";

//locales
import { useTranslation } from "react-i18next";

const Houses = () => {
  const { t } = useTranslation();

  const heroData = {
    img: "/assets/heros/houses-hero.jpg",
    heading: t("houses_helmet", { ns: "accommodations" }),
  };

  const [accommodationState, setAccommodationState] = useState(
    accommodationHouseInformation?.accommodations
  );

  const [loading, setLoading] = useState(true);

  //filter

  const filterData = [
    {
      id: 0,
      name: "houses_filter_all",
      active: false,
    },
    {
      id: 1,
      name: "houses_filter_persons2",
      active: false,
    },
    {
      id: 2,
      name: "houses_filter_persons3",
      active: false,
    },
    {
      id: 3,
      name: "houses_filter_persons4",
      active: false,
    },
    {
      id: 4,
      name: "houses_filter_persons6",
      active: false,
    },
  ];

  const [filterState, setFilterState] = useState(filterData);

  const handleFilterSelection = (selectedId) => {
    setFilterState((prevState) => {
      return prevState.map((filter) => ({
        ...filter,
        active: filter.id === selectedId,
      }));
    });

    let filteredData;

    if (selectedId === 0) {
      filteredData = accommodationHouseInformation?.accommodations;
    } else if (selectedId === 1) {
      filteredData = accommodationHouseInformation?.accommodations.filter(
        (accommodation) => accommodation.persons <= 2
      );
    } else if (selectedId === 4) {
      filteredData = accommodationHouseInformation?.accommodations.filter(
        (accommodation) => accommodation.persons > 4
      );
    } else {
      filteredData = accommodationHouseInformation?.accommodations.filter(
        (accommodation) => accommodation.persons === selectedId + 1
      );
    }

    setAccommodationState(filteredData);
  };

  //filter -- end

  useEffect(() => {
    //set the default state when opening page
    handleFilterSelection(0);
    setAccommodationState(accommodationHouseInformation?.accommodations);

    setTimeout(() => {
      setLoading(!loading);
    }, [1000]);

    //eslint-disable-next-line
  }, []);

  return (
    <Wrapper>
      <Helmet>
        <title>{t("houses_helmet", { ns: "accommodations" })} - Kempings Plaucaki</title>
        <meta name="title" content="Mājiņas - Kempings Plaucaki" />
        <link rel="canonical" href="https://plaucaki.lv/houses" />
        <meta
          name="description"
          content="Katras mājiņas priekšā atrodas privāts galds un grila vieta, radot ideālu vietu ēdiena gatavošanai un baudīšanai brīvā dabā. Neaizmirstiet paņemt līdzi savas gultas veļas – tā padarīs jūsu naktsmieru vēl mājīgāku (gultas veļa nav iekļauta cenā)."
        />

        <meta property="og:title" content="Mājiņas - Kempings Plaucaki" />
        <meta property="og:url" content="https://plaucaki.lv/houses" />
        <meta
          property="og:desc"
          content="Katras mājiņas priekšā atrodas privāts galds un grila vieta, radot ideālu vietu ēdiena gatavošanai un baudīšanai brīvā dabā. Neaizmirstiet paņemt līdzi savas gultas veļas – tā padarīs jūsu naktsmieru vēl mājīgāku (gultas veļa nav iekļauta cenā)."
        />
        <meta
          property="og:image"
          content="https://plaucaki.lv/assets/seo/seo-img.png"
        />
      </Helmet>

      <SectionHero data={heroData} />

      <div className="house__container">
        <ul className="house__container__filter filter-container container-center">
          {filterState?.map((filter) => {
            const { id, name, active } = filter;

            return (
              <li
                key={id}
                className="house__container__filter__item"
                style={{
                  backgroundColor: active ? "var(--green)" : "var(--white)",
                  color: active ? "var(--white)" : "var(--green)",
                }}
                onClick={() => {
                  handleFilterSelection(id);
                  window.scrollTo(0, 200);
                }}
              >
                {t(name, { ns: "accommodations" })}
              </li>
            );
          })}
        </ul>

        <HousesWrapper id="houses-list" className="container-center">
          {loading ? (
            <>
              {Array.from({ length: 12 }).map((_, index) => (
                  <Skeleton height={200} key={index} />
                ))}
            </>
          ) : (
            <>
              {accommodationState?.map((accommodation) => (
                <AccommodationCard
                  key={accommodation?.id}
                  data={accommodation}
                />
              ))}
            </>
          )}
        </HousesWrapper>
      </div>
    </Wrapper>
  );
};

const HousesWrapper = styled.section`
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 16px;

  @media screen and (max-width: 1020px) {
    grid-template-columns: repeat(3, 1fr);
  }

  @media screen and (max-width: 760px) {
    grid-template-columns: repeat(2, 1fr);
  }

  @media screen and (max-width: 600px) {
    grid-template-columns: repeat(1, 1fr);
  }
`;

const Wrapper = styled.main`
  .house__container {
    margin: 40px auto;

    &__filter {
      display: flex;
      z-index: 90;
      gap: 16px;
      margin-bottom: 40px;
      overflow-x: scroll;
      position: sticky;
      top: 125px;
      background: white;
      padding: 16px 20px;

      &__item {
        padding: 8px 24px;
        background: var(--white);
        color: var(--green);
        border-radius: 64px;
        border: 1px solid var(--green);
        transition: 0.3s ease-in-out;
        cursor: pointer;
        white-space: nowrap;
      }

      @media screen and (max-width: 960px) {
        max-width: 999px;
      }
    }

    .filter-container::-webkit-scrollbar {
      display: none;
    }
  }
`;

export default Houses;
