import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { Link } from "react-router-dom";
import axios from "axios";
import { useNavigate } from "react-router-dom";

//date range
import { DateRangePicker } from "react-date-range";
import { lv } from "date-fns/locale";
import { format } from "date-fns";

//hooks
import useGenerateDisabledDates from "../../hooks/useGenerateDisabledDates";

//components
import { RotatingLines } from "react-loader-spinner";
import Skeleton from "react-loading-skeleton";
import Alert from "../Alert";

//redux
import { updateReservationConfirmationDetails } from "../../redux/appState";
import { useDispatch } from "react-redux";

//locales
import { useTranslation } from "react-i18next";

const ReservationForm = ({ data }) => {
  const { t } = useTranslation();
  const { persons, price, bookedDates = [], id } = data;

  const navigate = useNavigate();
  const dispatch = useDispatch();

  //calendar
  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(new Date());

  const handleSelect = (ranges) => {
    const newStartDate = ranges.selection.startDate;
    const newEndDate = ranges.selection.endDate;

    setStartDate(newStartDate);
    setEndDate(newEndDate);

    setReservationData((reservationData) => ({
      ...reservationData,
      checkInDate: format(newStartDate, "yyyy-MM-dd"),
      checkOutDate: format(newEndDate, "yyyy-MM-dd"),
      checkInDateConfirmed: format(newStartDate, "dd.MM.yyyy"),
      checkOutDateConfirmed: format(newEndDate, "dd.MM.yyyy"),
    }));
  };

  const selectionRange = {
    startDate: startDate,
    endDate: endDate,
    key: "selection",
  };

  const disabledDates = useGenerateDisabledDates();
  const [reservedDates, setReservedDates] = useState([]);

  //calendar --end

  //new booking handling

  const defaultReservationData = {
    accommodationId: id,
    persons: 1,
    checkInDate: format(startDate, "yyyy-MM-dd"),
    checkOutDate: format(endDate, "yyyy-MM-dd"),
    status: "Pending",
    totalPrice: 0,
    totalNights: 0,
    isCheckedRules: false,
    name: "",
    email: "",
    number: "",
    note: "",
  };

  const [reservationData, setReservationData] = useState({
    accommodationId: id,
    persons: 1,
    checkInDate: format(startDate, "yyyy-MM-dd"),
    checkOutDate: format(endDate, "yyyy-MM-dd"),
    status: "Pending",
    totalPrice: 0,
    totalNights: 0,
    isCheckedRules: false,
    name: "",
    email: "",
    number: "",
    note: "",
  });

  const [loadingStates, setLoadingStates] = useState({
    loading: false,
    calendarLoading: true,
  });

  //true if not, false if ready
  const [readyToCreate, setReadyToCreate] = useState(true);

  const handleCreateReservation = async (e) => {
    e.preventDefault();

    try {
      setLoadingStates({
        loading: true,
        calendarLoading: true,
      });

      const response = await axios.post(
        `${process.env.REACT_APP_API_BASE_URL}/bookings.php`,
        reservationData,
        {
          headers: {
            "X-API-KEY": process.env.REACT_APP_API_KEY,
          },
        }
      );

      if (response.status === 200) {
        dispatch(
          updateReservationConfirmationDetails({
            accommodationId: reservationData?.accommodationId,
            startDate: reservationData?.checkInDateConfirmed,
            endDate: reservationData?.checkOutDateConfirmed,
            totalNights: reservationData?.totalNights,
            totalPrice: reservationData?.totalPrice,
          })
        );

        setTimeout(() => {
          navigate("/confirmation");
          window.scrollTo(0, 0);
        }, [1000]);
      }
    } catch (error) {
      setAlertText("Izveidojās kļūda veidojot rezervāciju. Mēģini vēlreiz.");
      setLoadingStates({
        loading: false,
        calendarLoading: false,
      });
    }
  };

  const [alertText, setAlertText] = useState("");

  const handlePersonsCount = (type) => {
    if (type === "plus") {
      if (reservationData?.persons < persons) {
        setReservationData((data) => ({ ...data, persons: data?.persons + 1 }));
      }
    } else {
      if (reservationData?.persons > 1) {
        setReservationData((data) => ({ ...data, persons: data?.persons - 1 }));
      }
    }
  };

  const handleTotalNightsCount = () => {
    const millisecondsInDay = 86400000;
    const daysDifference = (endDate - startDate) / millisecondsInDay;
    const selectedDatesCount = Math.abs(daysDifference);

    if (selectedDatesCount > 0) {
      setReservationData((reservationData) => ({
        ...reservationData,
        totalPrice: selectedDatesCount * price,
        totalNights: selectedDatesCount,
      }));
    } else {
      setReservationData((reservationData) => ({
        ...reservationData,
        totalPrice: 0,
        totalNights: 0,
      }));
    }
  };

  //count total nights stayed
  useEffect(() => {
    handleTotalNightsCount();
    //eslint-disable-next-line
  }, [startDate, endDate]);

  //handling booked dates when changing page
  useEffect(() => {
    setLoadingStates((loadingStates) => ({
      ...loadingStates,
      calendarLoading: true,
    }));

    setReservationData(defaultReservationData);
    setStartDate(new Date());
    setEndDate(new Date());

    if (bookedDates?.length !== 0) {
      const bookedDatesFromDb = bookedDates.flatMap((booking) => {
        const startDate = new Date(booking.check_in_date);
        const endDate = new Date(booking.check_out_date);
        const dates = [];
        let currentDate = startDate;

        while (currentDate < endDate) {
          const dateString = currentDate.toISOString().slice(0, 10);
          dates.push(dateString);
          currentDate.setDate(currentDate.getDate() + 1);
        }

        return dates;
      });

      const updatedBookedDates = [...disabledDates, ...bookedDatesFromDb];

      setReservedDates(updatedBookedDates);
    } else {
      setReservedDates(disabledDates);
    }

    setTimeout(() => {
      setLoadingStates({
        loading: false,
        calendarLoading: false,
      });
    }, [1000]);
    //eslint-disable-next-line
  }, [bookedDates]);

  //checking inputs before submitting
  useEffect(() => {
    const isInputValid = (input) => input.trim().length > 0;

    const checkIfFormIsValid = () => {
      const isFormValid = [
        isInputValid(reservationData.name),
        isInputValid(reservationData.email),
        isInputValid(reservationData.number),
        startDate && endDate && startDate < endDate,
        reservationData.isCheckedRules,
      ].every(Boolean);

      setReadyToCreate(!isFormValid);
    };

    checkIfFormIsValid();
    //eslint-disable-next-line
  }, [reservationData, startDate, endDate]);

  //new booking handling --end

  return (
    <Wrapper id="accommodation-form">
      <div className="container__right">
        <form onSubmit={handleCreateReservation}>
          <div className="form__elem">
            <h3>
              {t("reservation_heading_persons", { ns: "reservationform" })}*
            </h3>
            {loadingStates?.loading ? (
              <>
                <Skeleton height={40} />
              </>
            ) : (
              <>
                <div className="form__elem__input__persons">
                  <p>
                    {reservationData?.persons}{" "}
                    {t("reservation_persons", { ns: "reservationform" })}
                  </p>

                  <div className="form__elem__input__persons__toggle">
                    <button
                      type="button"
                      className="minus-person"
                      onClick={() => handlePersonsCount("minus")}
                      style={{
                        backgroundColor:
                          reservationData?.persons === persons
                            ? "var(--green)"
                            : "transparent",
                      }}
                    >
                      <p
                        style={{
                          color:
                            reservationData?.persons === persons
                              ? "var(--white)"
                              : "var(--black)",
                        }}
                      >
                        -
                      </p>
                    </button>
                    <button
                      type="button"
                      className="plus-person"
                      onClick={() => handlePersonsCount("plus")}
                      style={{
                        backgroundColor:
                          reservationData?.persons < persons
                            ? "var(--green)"
                            : "transparent",
                      }}
                    >
                      <p
                        style={{
                          color:
                            reservationData?.persons === persons
                              ? "var(--black)"
                              : "var(--white)",
                        }}
                      >
                        +
                      </p>
                    </button>
                  </div>
                </div>
              </>
            )}
          </div>

          <div className="form__elem">
            <label htmlFor="name">
              {t("reservation_heading_name", { ns: "reservationform" })}*
            </label>
            <div className="form__elem__input">
              <input
                id="name"
                type="text"
                name="name"
                value={reservationData?.name}
                onChange={(e) =>
                  setReservationData((reservationData) => ({
                    ...reservationData,
                    name: e.target.value,
                  }))
                }
                maxLength={50}
                disabled={loadingStates?.loading}
                required
              />
            </div>
          </div>

          <div className="form__elem">
            <label htmlFor="date-range">
              {t("reservation_heading_stay", { ns: "reservationform" })}*
            </label>
            <div className="form__elem__input">
              {loadingStates?.calendarLoading ? (
                <>
                  <Skeleton height={50} />
                  <Skeleton count={6} height={40} />
                </>
              ) : (
                <>
                  <DateRangePicker
                    ranges={[selectionRange]}
                    weekStartsOn={1}
                    showMonthAndYearPickers={false}
                    locale={lv}
                    rangeColors={["var(--green)"]}
                    minDate={new Date()}
                    onChange={handleSelect}
                    id="date-range"
                    disabledDates={reservedDates}
                  />
                </>
              )}
            </div>
          </div>

          <div className="form__elem">
            <label htmlFor="number">
              {t("reservation_heading_number", { ns: "reservationform" })}*
            </label>

            <div className="form__elem__input">
              <input
                id="number"
                type="number"
                name="number"
                pattern="[0-9]*"
                inputMode="numeric"
                value={reservationData?.number}
                onChange={(e) =>
                  setReservationData((reservationData) => ({
                    ...reservationData,
                    number: e.target.value,
                  }))
                }
                maxLength={50}
                disabled={loadingStates?.loading}
                required
              />
            </div>
          </div>

          <div className="form__elem">
            <label htmlFor="email">
              {t("reservation_heading_email", { ns: "reservationform" })}*
            </label>

            <div className="form__elem__input">
              <input
                id="email"
                type="email"
                name="email"
                value={reservationData?.email}
                onChange={(e) =>
                  setReservationData((reservationData) => ({
                    ...reservationData,
                    email: e.target.value,
                  }))
                }
                maxLength={50}
                disabled={loadingStates?.loading}
                required
              />
            </div>

            <p className="note text-sm">
              {t("reservation_email_desc", { ns: "reservationform" })}
            </p>
          </div>

          <div className="form__elem">
            <label htmlFor="notes">
              {t("reservation_heading_notes", { ns: "reservationform" })}
            </label>

            <div className="form__elem__input">
              <textarea
                id="notes"
                name="message"
                disabled={loadingStates?.loading}
                value={reservationData?.note}
                onChange={(e) =>
                  setReservationData((reservationData) => ({
                    ...reservationData,
                    note: e.target.value,
                  }))
                }
                maxLength={500}
              ></textarea>
            </div>
          </div>

          <ReservationTotal>
            {reservationData?.totalNights > 0 && (
              <>
                <div className="total__top">
                  <div className="total__top__arrival">
                    <h4 className="text-sm">
                      {t("reservation_arrival", { ns: "reservationform" })}
                    </h4>
                    <p className="date text-xl">
                      {format(startDate, "dd.MM.yyyy")}
                    </p>
                    <p className="arrival-time">14:00 - 21:00</p>
                  </div>

                  <div className="total__top__departure">
                    <h4 className="text-sm">
                      {t("reservation_departure", { ns: "reservationform" })}
                    </h4>
                    <p className="date text-xl">
                      {format(endDate, "dd.MM.yyyy")}
                    </p>
                    <p className="departure-time">
                      {t("reservation_departure_to", { ns: "reservationform" })}{" "}
                      12:00
                    </p>
                  </div>
                </div>
              </>
            )}

            <div className="total__bottom">
              <div className="total__bottom__item">
                <h3 className="text-xl">
                  {t("reservation_heading_price", { ns: "reservationform" })}
                </h3>
                <p className="text-xl">€ {price}</p>
              </div>

              {reservationData?.totalNights > 0 && (
                <>
                  <div className="total__bottom__item">
                    <h3 className="text-xl">
                      {t("reservation_heading_price_total", {
                        ns: "reservationform",
                      })}
                    </h3>
                    <p className="text-xl">€ {reservationData?.totalPrice}</p>
                  </div>
                </>
              )}
            </div>
          </ReservationTotal>

          <div className="form__elem">
            <div className="form__elem__input">
              <label className="checkmark-container">
                <p>
                  {t("reservation_privacy_policy1", { ns: "reservationform" })}{" "}
                  <Link to="/rules" target="_blank">
                    {t("reservation_privacy_policy2", {
                      ns: "reservationform",
                    })}
                  </Link>
                  .
                </p>
                <input
                  onChange={() =>
                    setReservationData((reservationData) => ({
                      ...reservationData,
                      isCheckedRules: !reservationData?.isCheckedRules,
                    }))
                  }
                  type="checkbox"
                  checked={reservationData?.isCheckedRules}
                  disabled={loadingStates?.loading}
                  required
                />
                <span className="checkmark"></span>
              </label>
            </div>
          </div>

          {alertText && <Alert type="error" text={alertText} />}

          <div className="form__elem">
            <button
              disabled={loadingStates?.loading || readyToCreate}
              style={{
                backgroundColor: loadingStates?.loading && "var(--grey)",
              }}
              type="submit"
              className="submit-button"
            >
              {loadingStates?.loading ? (
                <LoadingContainer>
                  <RotatingLines
                    visible={true}
                    height="18"
                    width="18"
                    strokeColor="var(--white)"
                    strokeWidth="5"
                    animationDuration="0.75"
                    ariaLabel="rotating-lines-loading"
                  />
                </LoadingContainer>
              ) : (
                t("reservation_btn", { ns: "reservationform" })
              )}
            </button>
          </div>
        </form>
      </div>
    </Wrapper>
  );
};

const LoadingContainer = styled.div`
  position: relative;
  top: 2px;
`;

const ReservationTotal = styled.div`
  width: 100%;
  border-top: 1px dashed var(--lightgrey);
  border-bottom: 1px dashed var(--lightgrey);
  padding: 16px 0;

  .total__top {
    display: flex;
    justify-content: space-between;
    gap: 16px;
    flex-wrap: wrap;
    margin: 0 0 16px 0;

    h4 {
      font-weight: 400;
    }

    .date {
      margin: 4px 0 0;
    }

    .departure-time,
    .arrival-time {
      color: var(--grey);
    }
  }

  .total__bottom {
    display: flex;
    flex-direction: column;
    margin: 8px 0 0 0;
    gap: 16px;

    &__item {
      display: flex;
      align-items: center;
      justify-content: space-between;
      gap: 16px;
      flex-wrap: wrap;
    }

    h3,
    p {
      font-weight: 500;
    }
  }
`;

const Wrapper = styled.section`
  @media screen and (max-width: 960px) {
    flex-grow: 1;
    display: flex;
  }

  .container__right {
    max-width: 400px;
    min-width: 400px;
    width: 100%;
    height: auto;
    background-color: var(--white);
    border-radius: 8px;
    position: relative;
    bottom: 100px;
    box-shadow: rgba(0, 0, 0, 0.05) 0px 6px 24px 0px,
      rgba(0, 0, 0, 0.08) 0px 0px 0px 1px;

    @media screen and (max-width: 960px) {
      bottom: 0;
      max-width: 100%;
      min-width: unset;
    }

    form {
      display: flex;
      flex-direction: column;
      gap: 16px;
      padding: 24px;

      .form__elem {
        width: 100%;

        .checkmark-container input:checked ~ .checkmark:after {
          display: block;
        }

        .checkmark-container input:checked ~ .checkmark {
          background-color: var(--green);
        }

        .checkmark-container:hover input ~ .checkmark {
          background-color: var(--green);
        }

        .checkmark-container {
          display: block;
          position: relative;
          padding-left: 35px;
          margin-bottom: 12px;
          cursor: pointer;
          font-size: 0.9rem;
          -webkit-user-select: none;
          -moz-user-select: none;
          -ms-user-select: none;
          user-select: none;

          p,
          a {
            font-weight: 400;
          }

          a {
            text-decoration: underline;
          }

          .checkmark:after {
            content: "";
            position: absolute;
            display: none;
            left: 8px;
            top: 3px;
            width: 7px;
            height: 12px;
            border: solid white;
            border-width: 0 3px 3px 0;
            -webkit-transform: rotate(45deg);
            -ms-transform: rotate(45deg);
            transform: rotate(45deg);
          }

          .checkmark {
            position: absolute;
            top: 0;
            left: 0;
            height: 25px;
            width: 25px;
            border-radius: 8px;
            background-color: var(--grey);
          }

          input {
            position: absolute;
            opacity: 0;
            cursor: pointer;
            height: 0;
            width: 0;
          }
        }

        .rdrDateRangePickerWrapper {
          width: 100%;
          font-size: 0.9rem !important;

          .rdrDefinedRangesWrapper {
            display: none;
          }

          .rdrDateDisplayWrapper {
            border-radius: 8px;
            background-color: transparent;

            .rdrDateDisplay {
              color: var(--green) !important;
              margin: 0;

              .rdrDateDisplayItem {
                border-radius: 8px;
              }

              input {
                border: none;
                border-radius: 8px;
              }
            }
          }

          .rdrCalendarWrapper {
            width: 100%;
            color: var(--green);

            .rdrMonthsVertical {
              flex-direction: row;
            }

            .rdrMonth {
              max-width: 100%;
              width: 100% !important;
              padding: 0;

              .rdrWeekDay {
                font-size: 0.875rem !important;
              }
            }

            .rdrDayDisabled .rdrDayNumber span {
              color: var(--grey);
            }
          }
        }

        .submit-button {
          padding: 8px 24px;
          background: var(--green);
          color: var(--white);
          border-radius: 8px;
          transition: 0.3s ease-in-out;
          width: 100%;
          font-size: 1rem;
          cursor: pointer;
        }

        .submit-button:disabled {
          background-color: var(--grey);
          cursor: not-allowed;
        }

        .note {
          color: var(--grey);
          margin: 8px 0 0 0;
        }

        h3,
        label {
          color: var(--green);
          font-weight: 600;
          font-size: 1rem;
        }

        &__input {
          margin: 8px 0 0 0;

          input,
          textarea {
            min-width: 100%;
            padding: 8px 16px;
            border: 1px solid var(--grey);
            outline: none;
            border-radius: 8px;
          }

          input[type="number"]::-webkit-inner-spin-button,
          input[type="number"]::-webkit-outer-spin-button {
            -webkit-appearance: none;
            margin: 0;
          }

          textarea {
            resize: none;
            min-height: 150px;
          }
        }

        &__input__persons {
          display: flex;
          justify-content: space-between;
          margin: 8px 0 0 0;

          &__toggle {
            display: flex;
            gap: 16px;

            button {
              width: 32px;
              height: 32px;
              border-radius: 50%;
              cursor: pointer;
              transition: 0.3s ease-in-out;
              font-size: 1.5rem;
              display: flex;
              align-items: center;
              justify-content: center;

              p {
                font-size: 1.87rem;
                position: relative;
                bottom: 1px;
              }
            }
          }
        }
      }
    }
  }
`;

export default ReservationForm;
