import React, { useState } from "react";
import styled from "styled-components";
import { motion } from "framer-motion";

import Lightbox from "react-image-lightbox";
import "react-image-lightbox/style.css";

const Hero = ({ images = [] }) => {
  const [index, setIndex] = useState(-1);

  const nextIndex = images.length > 0 ? (index + 1) % images.length : 0;
  const prevIndex =
    images.length > 0 ? (index + images.length - 1) % images.length : 0;
  const currentImage = images[index] || "";
  const nextImage = images[nextIndex] || currentImage;
  const prevImage = images[prevIndex] || currentImage;

  const handleClick = (index) => setIndex(index);
  const handleClose = () => setIndex(-1);
  const handleMovePrev = () => setIndex(prevIndex);
  const handleMoveNext = () => setIndex(nextIndex);

  return (
    <Wrapper id="accommodation-hero">
      {images?.map((image, i) => (
        <motion.img
          layout
          initial={{ opacity: 0, filter: "blur(3px)" }}
          animate={{ opacity: 1, filter: "blur(0)" }}
          exit={{ opacity: 0, filter: "blur(3px)" }}
          transition={{
            opacity: { duration: 0.4 },
            filter: { duration: 0.8 },
          }}
          key={image}
          src={image}
          alt="house-img"
          onClick={() => handleClick(i)}
        />
      ))}

      {index >= 0 && (
        <>
          <Lightbox
            mainSrc={currentImage}
            mainSrcThumbnail={currentImage}
            nextSrc={nextImage}
            nextSrcThumbnail={nextImage}
            prevSrc={prevImage}
            prevSrcThumbnail={prevImage}
            onCloseRequest={handleClose}
            onMovePrevRequest={handleMovePrev}
            onMoveNextRequest={handleMoveNext}
          />
        </>
      )}
    </Wrapper>
  );
};

const Wrapper = styled(motion.section)`
  background-color: var(--lightgrey);
  max-height: 500px;
  height: 100%;
  width: 100vw;
  display: grid;
  grid-template-columns: 1.75fr 1fr 1.25fr;
  grid-gap: 4px;

  img {
    height: 500px;
    width: 100%;
    object-fit: cover;
    cursor: pointer;
  }

  img:hover {
    opacity: 0.9 !important;
  }

  @media (max-width: 960px) {
    grid-template-columns: 1.75fr 1.25fr;
    grid-template-rows: auto auto;
    grid-template-areas:
      "image1 image2"
      "image1 image3";
    max-height: 600px;

    img {
      height: 600px;
    }

    img:nth-child(1) {
      grid-area: image1;
      height: 604px;
    }

    img:nth-child(2) {
      grid-area: image2;
      height: 400px;
    }

    img:nth-child(3) {
      grid-area: image3;
      height: 200px;
    }
  }
`;

export default Hero;
