import React, { useState, useEffect } from "react";
import styled from "styled-components";

//accommodation data
import { accommodationData } from "../../../data/accommodationData";

const MostBookedHouses = ({ name, desc, Icon, data }) => {
  const [popularHouseList, setPopularHouseList] = useState([]);

  const createHouse = (id, name, image, count) => {
    return {
      id,
      name,
      image,
      count,
    };
  };

  const createPopularHouseList = () => {
    for (let i = 0; i < data?.length; i++) {
      const houseData = accommodationData?.filter(
        (accommodation) => accommodation.id === data[i]?.accommodation_id
      );

      const popularHouse = createHouse(
        houseData[0]?.id,
        houseData[0]?.name,
        houseData[0]?.images?.[0],
        data[i]?.booking_count
      );

      setPopularHouseList((popularHouseList) => [
        ...popularHouseList,
        popularHouse,
      ]);
    }
  };

  useEffect(() => {
    setPopularHouseList([]);

    if (data) {
      createPopularHouseList();
    }
    //eslint-disable-next-line
  }, [data]);

  return (
    <Wrapper>
      <WidgetTop>
        <div>
          <h4 className='text-lg'>{name}</h4>
          <p className="text-sm">{desc}</p>
        </div>

        <div className="icon-container">{<Icon className="icon" />}</div>
      </WidgetTop>

      <WidgetContent>
        {popularHouseList?.map((house) => {
          const { id, name, image, count } = house;

          return (
            <House key={id}>
              <div className="house__image">
                <img src={image} alt="house-image" />
              </div>

              <div className="house__data">
                <p className="house__data__name">{name}</p>
                <p className="house__data__reservations text-sm">
                  {count} rezervācijas
                </p>
              </div>
            </House>
          );
        })}
      </WidgetContent>
    </Wrapper>
  );
};

const House = styled.div`
  display: flex;
  gap: 16px;
  align-items: center;

  .house__image {
    width: 100px;
    height: 50px;

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }

  .house__data {
    &__name {
      font-weight: 500;
    }

    &__reservations {
      color: var(--grey);
    }

    &__name,
    &__reservations {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      max-width: 200px;
    }
  }
`;

const WidgetContent = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
`;

const WidgetTop = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  gap: 24px;
  margin: 0 0 16px 0;

  p {
    color: var(--grey);
  }

  .icon-container {
    padding: 5px 8px 8px;
    background-color: var(--green);
    border-radius: 8px;

    .icon {
      min-width: 18px;
      max-width: 18px;
      width: 100%;
      color: var(--white);
    }
  }
`;

const Wrapper = styled.div`
  box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 3px 0px,
    rgba(27, 31, 35, 0.15) 0px 0px 0px 1px;
  padding: 16px;
  border-radius: 8px;
`;

export default MostBookedHouses;
