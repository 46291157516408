import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { Gallery as ReactGridGallery } from "react-grid-gallery";

//components
import SectionHeroSimple from "../components/SectionHeroSimple";
import Skeleton from "react-loading-skeleton";
import Lightbox from "react-image-lightbox";

//data
import { images } from "../data/galleryData";

//helmet
import { Helmet } from "react-helmet";

//locales
import { useTranslation } from "react-i18next";

const Gallery = () => {
  const { t } = useTranslation();

  const [loading, setLoading] = useState(true);

  const [index, setIndex] = useState(-1);

  const currentImage = images[index];
  const nextIndex = (index + 1) % images.length;
  const nextImage = images[nextIndex] || currentImage;
  const prevIndex = (index + images.length - 1) % images.length;
  const prevImage = images[prevIndex] || currentImage;

  const handleClick = (index, item) => setIndex(index);
  const handleClose = () => setIndex(-1);
  const handleMovePrev = () => setIndex(prevIndex);
  const handleMoveNext = () => setIndex(nextIndex);

  useEffect(() => {
    setTimeout(() => {
      setLoading(false);
    }, [2000]);
  }, []);

  return (
    <Wrapper>
      <Helmet>
        <title>{t("gallery", { ns: "navigation" })} - Kempings Plaucaki</title>
        <meta name="title" content="Galerija - Kempings Plaucaki" />
        <link rel="canonical" href="https://plaucaki.lv/gallery" />
        <meta
          name="description"
          content="Iepazīstieties ar apkārtni, kuru iespējams redzēt Kempingā Plaucaki, kurš atrodas Pūrciemā"
        />

        <meta property="og:title" content="Galerija - Kempings Plaucaki" />
        <meta property="og:url" content="https://plaucaki.lv/gallery" />
        <meta
          property="og:desc"
          content="Iepazīstieties ar apkārtni, kuru iespējams redzēt Kempingā Plaucaki, kurš atrodas Pūrciemā"
        />
        <meta
          property="og:image"
          content="https://plaucaki.lv/assets/seo/seo-img.png"
        />
      </Helmet>

      <SectionHeroSimple
        data={{ heading: t("gallery", { ns: "navigation" }) }}
      />

      {loading ? (
        <>
          <GalleryLoaderContainer className="container-center">
            {Array.from({ length: 16 }).map((_, index) => (
              <Skeleton height={180} key={index} />
            ))}
          </GalleryLoaderContainer>
        </>
      ) : (
        <>
          <GalleryContainer className="container-center">
            <ReactGridGallery
              images={images}
              enableImageSelection={false}
              onClick={handleClick}
            />
          </GalleryContainer>
        </>
      )}

      {!!currentImage && (
        <Lightbox
          mainSrc={currentImage.original}
          imageTitle={currentImage.caption}
          mainSrcThumbnail={currentImage.src}
          nextSrc={nextImage.original}
          nextSrcThumbnail={nextImage.src}
          prevSrc={prevImage.original}
          prevSrcThumbnail={prevImage.src}
          onCloseRequest={handleClose}
          onMovePrevRequest={handleMovePrev}
          onMoveNextRequest={handleMoveNext}
        />
      )}
    </Wrapper>
  );
};

const GalleryLoaderContainer = styled.section`
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-gap: 4px;
  margin: 80px auto 40px;

  @media (max-width: 900px) {
    grid-template-columns: repeat(3, 1fr);
  }

  @media (max-width: 600px) {
    grid-template-columns: repeat(2, 1fr);
  }
`;

const GalleryContainer = styled.section`
  margin: 80px auto 40px;

  img {
    object-fit: cover;
  }
`;

const Wrapper = styled.main``;

export default Gallery;
