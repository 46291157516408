import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { Link, useNavigate } from "react-router-dom";
import { motion } from "framer-motion";

//redux
import {
  updateTriggerContainerScrolled,
  updateReservationConfirmationDetails,
  selectReservationConfirmationDetails,
} from "../redux/appState";
import { useDispatch, useSelector } from "react-redux";

//data
import { accommodationData } from "../data/accommodationData";

//component
import ConfirmationCard from "../components/accommodation/ConfirmationCard";

//locales
import { useTranslation } from "react-i18next";

const Confirmation = () => {
  const { t } = useTranslation();

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);

  const reservationDetails = useSelector(selectReservationConfirmationDetails);

  useEffect(() => {
    if (reservationDetails === null) {
      navigate("/");
      return;
    } else {
      setLoading(false);
    }

    dispatch(updateTriggerContainerScrolled(true));

    return () => {
      dispatch(updateTriggerContainerScrolled(false));
      dispatch(updateReservationConfirmationDetails(null));
    };

    //eslint-disable-next-line
  }, [dispatch]);

  return (
    <Wrapper id="reservation-confirmation">
      <div className="confirmation-container container-center">
        <ConfirmationMessage
          initial={{ opacity: 0, x: -20 }}
          animate={{ opacity: 1, x: 0 }}
          exit={{ opacity: 0, y: -20 }}
          transition={{ duration: 0.4 }}
        >
          <div className="message">
            <h2 className="text-xl">
              {t("confirmation_heading", { ns: "confirmation" })}
            </h2>
            <p>{t("confirmation_p1", { ns: "confirmation" })}</p>
          </div>

          <div>
            <h3 className="text-xl">
              {t("confirmation_heading2", { ns: "confirmation" })}
            </h3>
            <p>{t("confirmation_p2", { ns: "confirmation" })}</p>
            <p>{t("confirmation_p3", { ns: "confirmation" })}</p>
          </div>

          <div className="button-container">
            <Link to="/" className="button">
              {t("confirmation_btn", { ns: "confirmation" })}
            </Link>
          </div>
        </ConfirmationMessage>

        {!loading && (
          <>
            <ConfirmationData>
              <ConfirmationCard
                data={
                  accommodationData[reservationDetails?.accommodationId - 1]
                }
                reservationData={{
                  startDate: reservationDetails?.startDate,
                  endDate: reservationDetails?.endDate,
                  totalNights: reservationDetails?.totalNights,
                  totalPrice: reservationDetails?.totalPrice,
                }}
              />
            </ConfirmationData>
          </>
        )}
      </div>
    </Wrapper>
  );
};

const ConfirmationData = styled.section`
  max-width: 400px;
  min-width: 400px;
  width: 100%;

  @media (max-width: 600px) {
    max-width: unset;
    min-width: unset;
  }
`;

const ConfirmationMessage = styled(motion.section)`
  display: flex;
  flex-direction: column;
  gap: 24px;
  flex-grow: 1;

  .button-container {
    margin: 24px 0 0 0;
  }

  h2,
  h3 {
    margin-bottom: 16px;
  }
`;

const Wrapper = styled.main`
  padding: 200px 0;

  @media (max-width: 840px) {
    padding: 150px 0;

    .confirmation-container {
      flex-direction: column;
      gap: 40px;
    }
  }

  .confirmation-container {
    display: flex;
    gap: 24px;

    @media (max-width: 840px) {
      flex-direction: column;
      gap: 40px;
    }
  }
`;

export default Confirmation;
