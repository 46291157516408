import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { motion } from "framer-motion";
import axios from "axios";

//components
import SectionHeroSimple from "../components/SectionHeroSimple";
import AccommodationCard from "../components/houses/AccommodationCard";
import Skeleton from "react-loading-skeleton";

//date range
import { DateRangePicker } from "react-date-range";
import { lv } from "date-fns/locale";
import { format } from "date-fns";

//hooks
import useGenerateDisabledDates from "../hooks/useGenerateDisabledDates";

//data
import { accommodationData } from "../data/accommodationData";

//icons
import { Search } from "@styled-icons/fluentui-system-filled/Search";

//helmet
import { Helmet } from "react-helmet";

//locales
import { useTranslation } from "react-i18next";

const Reserve = () => {
  const { t } = useTranslation();

  //calendar
  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(new Date());

  const [accommodations, setAccommodations] = useState(accommodationData);
  const [loading, setLoading] = useState(true);

  const [availability, setAvailability] = useState({
    calendarOpened: false,
    searchStartDate: null,
    searchEndDate: null,
  });

  const handleSelect = (ranges) => {
    setStartDate(ranges.selection.startDate);
    setEndDate(ranges.selection.endDate);
  };

  const selectionRange = {
    startDate: startDate,
    endDate: endDate,
    key: "selection",
  };

  const disabledDates = useGenerateDisabledDates();
  //calendar --end

  const handleGetReservations = () => {
    if (format(startDate, "yyyy-MM-dd") === format(endDate, "yyyy-MM-dd")) {
      window.alert("Iebraukšanas un izbraukšanas datums nevar būt vienāds.");
      return;
    }

    setLoading(true);

    setAvailability((availability) => ({
      ...availability,
      calendarOpened: !availability.calendarOpened,
      searchStartDate: startDate,
      searchEndDate: endDate,
    }));

    axios
      .get(
        `${process.env.REACT_APP_API_BASE_URL}/bookings.php?start_date=${format(
          startDate,
          "yyyy-MM-dd"
        )}&end_date=${format(endDate, "yyyy-MM-dd")}`,
        {
          headers: {
            "X-API-KEY": process.env.REACT_APP_API_KEY,
          },
        }
      )
      .then((response) => {
        const { data } = response;

        const filteredAccommodations = accommodationData.filter(
          (accommodation) => !data.includes(accommodation.id)
        );

        setAccommodations(filteredAccommodations);

        setTimeout(() => {
          setLoading(false);
        }, [2000]);
      });

    return;
  };

  useEffect(() => {
    //first page load
    setAccommodations(accommodationData);
    setTimeout(() => {
      setLoading(!loading);
    }, [1000]);

    return () => setAccommodations(accommodationData);

    //eslint-disable-next-line
  }, []);

  return (
    <Wrapper>
      <Helmet>
        <title>{t("reserve", { ns: "header" })} - Kempings Plaucaki</title>
        <meta name="title" content="Rezervēt - Kempings Plaucaki" />
        <link rel="canonical" href="https://plaucaki.lv/reserve" />
        <meta
          name="description"
          content="Izbaudiet komfortu un privātumu Kempinga Plaucaki mājiņās. Katras mājiņas priekšā ir privāts galds un grila vieta, kas ideāli piemērota ēdiena gatavošanai un ēšanai brīvā dabā."
        />

        <meta property="og:title" content="Rezervēt - Kempings Plaucaki" />
        <meta property="og:url" content="https://plaucaki.lv/reserve" />
        <meta
          property="og:desc"
          content="Atveries dabas skaistumam un izbaudi pilnīgu miera un saskaņas sajūtu tikai dažu soļu attālumā no jūras. Mūsu kempings ir paradīze tiem, kas vēlas izvairīties no ikdienas steigas un iegrimt dabas klusumā."
        />
        <meta
          property="og:image"
          content="https://plaucaki.lv/assets/seo/houses.png"
        />
      </Helmet>

      <SectionHeroSimple data={{ heading: t("reserve_heading", { ns: "reserve" }) }} />

      <section
        id="availability"
        className="availability__container container-center"
      >
        <SearchBox>
          <div
            className="search__box__container"
            onClick={() =>
              setAvailability((availability) => ({
                ...availability,
                calendarOpened: !availability.calendarOpened,
              }))
            }
          >
            <p style={{ color: "var(--grey)" }}>
              {availability.searchStartDate && availability.searchEndDate ? (
                <>
                  {t("reserve_from", { ns: "reserve" })}{" "}
                  {format(availability.searchStartDate, "dd.MM.yyyy")} {t("reserve_to", { ns: "reserve" })}{" "}
                  {format(availability.searchEndDate, "dd.MM.yyyy")}
                </>
              ) : (
                t("reserve_choose", { ns: "reserve" })
              )}
            </p>
            <div className="search__box__container__icon">
              <Search className="icon" />
            </div>
          </div>

          {availability?.calendarOpened && (
            <CalendarModal
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              transition={{ duration: 0.4 }}
            >
              <div className="availability__calendar">
                <DateRangePicker
                  ranges={[selectionRange]}
                  weekStartsOn={1}
                  showMonthAndYearPickers={false}
                  locale={lv}
                  rangeColors={["var(--green)"]}
                  minDate={new Date()}
                  onChange={handleSelect}
                  id="date-range"
                  disabledDates={disabledDates}
                />

                <div className="availability__calendar__button__container">
                  <button className="button" onClick={handleGetReservations}>
                    <p>{t("reserve_search", { ns: "reserve" })}</p>
                  </button>
                </div>
              </div>
            </CalendarModal>
          )}
        </SearchBox>

        <AvailableAccommodations>
          <div className="available__properties">
            {loading ? (
              <>
                {Array.from({ length: 12 }).map((_, index) => (
                  <Skeleton height={200} key={index} />
                ))}
              </>
            ) : (
              <>
                {accommodations?.map((accommodation) => (
                  <AccommodationCard
                    key={accommodation?.id}
                    data={accommodation}
                  />
                ))}
              </>
            )}
          </div>
        </AvailableAccommodations>
      </section>
    </Wrapper>
  );
};

const CalendarModal = styled(motion.div)`
  .availability__calendar {
    max-width: 400px;
    min-width: 250px;
    width: 100% !important;
    margin: 0 auto;
    position: absolute;
    top: 80px;
    box-shadow: rgba(0, 0, 0, 0.05) 0px 6px 24px 0px,
      rgba(0, 0, 0, 0.08) 0px 0px 0px 1px;
    background-color: var(--white);
    overflow-y: auto;

    @media (max-height: 700px) {
      max-height: 300px;
    }

    &__button__container {
      padding: 0 20px 20px;

      .button {
        width: 100%;
        cursor: pointer;

        p {
          color: var(--white);
        }
      }
    }

    border-radius: 8px;
    left: 50%;
    transform: translateX(-50%);

    .rdrDateRangePickerWrapper {
      width: 100%;
      font-size: 0.9rem !important;

      .rdrDefinedRangesWrapper {
        display: none;
      }

      .rdrDateDisplayWrapper {
        border-radius: 8px;
        background-color: transparent;

        .rdrDateDisplay {
          color: var(--green) !important;
          margin: 0;

          .rdrDateDisplayItem {
            border-radius: 8px;
          }

          input {
            border: none;
            border-radius: 8px;
          }
        }
      }

      .rdrCalendarWrapper {
        width: 100%;
        color: var(--green);
        padding: 20px;
        border-radius: 8px;

        .rdrMonthsVertical {
          flex-direction: row;
        }

        .rdrMonth {
          max-width: 100%;
          width: 100% !important;
          padding: 0;

          .rdrWeekDay {
            font-size: 0.875rem !important;
          }
        }

        .rdrDayDisabled .rdrDayNumber span {
          color: var(--grey);
        }
      }
    }
  }
`;

const AvailableAccommodations = styled.div`
  margin: 40px 0 0 0;

  .available__properties {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    gap: 16px;

    @media screen and (max-width: 1020px) {
      grid-template-columns: repeat(3, 1fr);
    }

    @media screen and (max-width: 760px) {
      grid-template-columns: repeat(2, 1fr);
    }

    @media screen and (max-width: 600px) {
      grid-template-columns: repeat(1, 1fr);
    }
  }
`;

const SearchBox = styled.div`
  position: sticky;
  top: 140px;
  z-index: 90;

  .search__box__container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    box-shadow: rgba(0, 0, 0, 0.05) 0px 6px 24px 0px,
      rgba(0, 0, 0, 0.08) 0px 0px 0px 1px;
    border-radius: 64px;
    padding: 6px 6px 6px 20px;
    cursor: pointer;
    gap: 24px;
    position: relative;
    background-color: var(--white);

    p {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }

    &__icon {
      background-color: var(--green);
      padding: 10px;
      border-radius: 50%;

      .icon {
        max-width: 24px;
        min-width: 24px;
        width: 100%;
        color: var(--white);
      }
    }
  }

  .search__box__container:hover {
    background-color: var(--lightgrey);
  }
`;

const Wrapper = styled.main`
  .availability__container {
    padding: 40px 20px;
  }
`;

export default Reserve;
