import React from "react";
import styled from "styled-components";
import { useLocation, Link } from "react-router-dom";

//icons
import { Whatsapp } from "@styled-icons/boxicons-logos/Whatsapp";

const ContactIcon = () => {
  const location = useLocation();
  const isAdminPath = location.pathname.includes("/admin");

  return (
    <Link target="_blank" to="https://wa.me/+37126445124">
      <Wrapper style={{ display: isAdminPath ? "none" : "block" }}>
        <Whatsapp className="icon" />
      </Wrapper>
    </Link>
  );
};

const Wrapper = styled.div`
  position: fixed;
  bottom: 40px;
  right: 40px;
  padding: 8px;
  border-radius: 50%;
  background-color: #25d366;
  cursor: pointer;
  animation: pulse 2s infinite;

  @media (max-width: 600px){
    right: 20px;
    bottom: 20px;
  }

  .icon {
    max-width: 32px;
    min-width: 32px;
    width: 100%;
    color: var(--white);
  }

  @keyframes pulse {
    0% {
      box-shadow: 0 0 0 0 rgba(37, 211, 102, 0.7);
    }
    70% {
      box-shadow: 0 0 0 20px rgba(37, 211, 102, 0);
    }
    100% {
      box-shadow: 0 0 0 0 rgba(37, 211, 102, 0);
    }
  }
`;

export default ContactIcon;
