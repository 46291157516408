const priceList = {
  twoBedroomHouse: 35,
  threeBedroomHouse: 40,
  fourBedroomHouse: 45,
  sixBedroomHouse: 70,
  tent: 10,
  trailer: 15,
  wood: 5,
  fireStarter: 1,
  shower: 3,
};

function createPrice(id, name, price, priceDesc) {
  return {
    id,
    name,
    price,
    priceDesc,
  };
}

const pricingTable = [
  createPrice(
    0,
    "pricing_two",
    `€${priceList.twoBedroomHouse}`,
    "pricing_per_night"
  ),
  createPrice(
    1,
    "pricing_three",
    `€${priceList.threeBedroomHouse}`,
    "pricing_per_night"
  ),
  createPrice(
    2,
    "pricing_four",
    `€${priceList.fourBedroomHouse}`,
    "pricing_per_night"
  ),
  createPrice(
    3,
    "pricing_six",
    `€${priceList.sixBedroomHouse}`,
    "pricing_per_night"
  ),
  createPrice(4, "pricing_tent", `€${priceList.tent}`, "pricing_per_night"),
  createPrice(
    5,
    "pricing_trailer",
    `€${priceList.trailer}`,
    "pricing_per_night"
  ),
  createPrice(6, "pricing_wood", `€${priceList.wood}`, "pricing_wood_bucket"),
  createPrice(
    7,
    "pricing_firestarter",
    `€${priceList.fireStarter}`,
    "pricing_firestarter_kit"
  ),
  createPrice(8, "pricing_shower", `€${priceList.shower}`, "pricing_per_using"),
];

export { priceList, pricingTable };
