import React from "react";
import styled from "styled-components";

//components
import SectionHeroSimple from "../components/SectionHeroSimple";

//data
import { pricingTable } from "../data/pricingData";

//helmet
import { Helmet } from "react-helmet";

//locales
import { useTranslation } from "react-i18next";

const Pricing = () => {

  const { t } = useTranslation();

  return (
    <Wrapper>
      <Helmet>
        <title>{t("pricing_helmet", { ns: "pricing" })} - Kempings Plaucaki</title>
        <meta name="title" content="Cenrādis - Kempings Plaucaki" />
        <link rel="canonical" href="https://plaucaki.lv/pricing" />
        <meta
          name="description"
          content="Iepazīstiet skaisto, mierīgo un gleznainu kempinga galamērķi Latvijā. Izbaudiet dabu, mieru un āra aktivitātes ideālā vietā atpūtai un piedzīvojumiem."
        />

        <meta property="og:title" content="Cenrādis - Kempings Plaucaki" />
        <meta property="og:url" content="https://plaucaki.lv/pricing" />
        <meta
          property="og:desc"
          content="Atklājiet Kempingu Plaucaki, lielisku izvēli dabas mīļotājiem, kas meklē mierīgu atpūtu Latvijā. Pārlūkojiet mūsu galeriju, lai redzētu iespaidīgās ainavas un iespējas, kas padara mūsu kempingu unikālu."
        />
        <meta
          property="og:image"
          content="https://plaucaki.lv/assets/seo/seo-img.png"
        />
      </Helmet>

      <SectionHeroSimple data={{ heading: t("pricing_helmet", { ns: "pricing" }) }} />

      <ul className="pricing__container container-center">
        {pricingTable?.map((item) => {
          const { id, name, price, priceDesc } = item;

          return (
            <li key={id} className="pricing__container__item">
              <h2 className="pricing__container__item__name">{t(name, { ns: "pricing" })}</h2>
              <div className="pricing__container__item__divider" />
              <p className="pricing__container__item__price">{price} / {t(priceDesc, { ns: "pricing" })}</p>
            </li>
          );
        })}
      </ul>
    </Wrapper>
  );
};

const Wrapper = styled.main`
  .pricing__container {
    padding: 80px 20px 40px;
    display: flex;
    flex-direction: column;
    gap: 24px;

    @media screen and (max-width: 680px) {
      gap: 40px;
    }

    &__item {
      display: flex;
      justify-content: space-between;
      align-items: center;
      gap: 16px;

      @media screen and (max-width: 680px) {
        flex-direction: column;
        gap: 8px;

        &__divider {
          display: none;
        }
      }

      &__name {
        font-weight: 600;
      }

      &__divider {
        height: 2px;
        flex-grow: 1;
        border-bottom: 1px dashed var(--grey);
        position: relative;
        bottom: 2px;
      }
    }
  }
`;

export default Pricing;
