import React from "react";
import styled from "styled-components";

const SectionHeroSimple = ({ data }) => {
  const { heading } = data;

  return (
    <Wrapper id="hero">
      <HeroText className="container-center">
        <div className="section__hero__text">
          <h1 className="text-3xl">{heading}</h1>
        </div>
      </HeroText>
    </Wrapper>
  );
};

const HeroText = styled.div`
  min-height: 200px;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;

  .section__hero__text {
    max-width: 400px;
    padding: 100px 0 0 0;

    h1 {
      font-weight: 600;
      color: var(--white);
      text-shadow: rgba(0, 0, 0, 0.2) 1px 5px 15px;
    }
  }
`;

const Wrapper = styled.section`
  background-color: var(--darkgreen);
`;

export default SectionHeroSimple;
