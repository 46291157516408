import React from "react";
import styled from "styled-components";

//components
import Hero from "../components/home/Hero";
import MainPoints from "../components/home/MainPoints";
import Accommodations from "../components/home/Accommodations";
import VideoBanner from "../components/home/VideoBanner";
import Reviews from "../components/home/Reviews";

//helmet
import { Helmet } from "react-helmet";

//locales
import { useTranslation } from "react-i18next";

const Home = () => {
  const { t } = useTranslation();

  return (
    <Wrapper>
      <Helmet>
        <title>{t("home_helmet", { ns: "home" })}  - Pūrciems, Latvija</title>
        <meta name="title" content="Kempings Plaucaki - Pūrciems, Latvija" />
        <link rel="canonical" href="https://plaucaki.lv/" />
        <meta
          name="description"
          content="Izbaudiet dabas skaistumu un pilnīgu mieru Kempingā Plaucaki, Latvijā. Ideāla vieta, lai atpūstos no ikdienas steigas un iegremdētos klusumā, tikai dažu soļu attālumā no jūras."
        />
        <meta
          name="keywords"
          content="kempings Latvijā, Plaucaki kempings, Baltā kāpa, romantiskas koka mājiņas, telšu vietas, treileru vietas, jūras tuvums, dabas skaistums, miera sajūta, saskaņa ar dabu, ainaviskās takas, ugunskura vietas, elektrības pieslēgums treileriem, pastaigas Baltā kāpa, dabas klusums, atpūta pie jūras, ģimenes atpūta, dabas izpēte, kempinga mājiņas, atpūtas vieta, ūdens uzpildes vieta, kempinga teritorija, Purciems, Roja, Kurzeme"
        />

        <meta
          property="og:title"
          content="Kempings Plaucaki - Pūrciems, Latvija"
        />
        <meta property="og:url" content="https://plaucaki.lv/" />
        <meta
          property="og:description"
          content="Izbaudiet mieru un dabu Kempingā Plaucaki, tikai dažu soļu attālumā no jūras. Ideāla vieta, lai atpūstos un izbaudītu Latvijas dabu."
        />
        <meta
          property="og:image"
          content="https://plaucaki.lv/assets/seo/seo-img.png"
        />
      </Helmet>

      <Hero />
      <MainPoints />
      <Accommodations />
      <VideoBanner />
      <Reviews />
    </Wrapper>
  );
};

const Wrapper = styled.main``;

export default Home;
