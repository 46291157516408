import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { Link, NavLink, useNavigate } from "react-router-dom";
import axios from "axios";
import { motion } from "framer-motion";

//redux
import { logout, selectCurrentUser } from "../../redux/authState";
import { useDispatch, useSelector } from "react-redux";

//icons
import { Notifications } from "@styled-icons/material-outlined/Notifications";
import { EmailOutline } from "@styled-icons/evaicons-outline/EmailOutline";
import { SignOut } from "@styled-icons/fluentui-system-regular/SignOut";
import { Home } from "@styled-icons/boxicons-regular/Home";
import { Calendar2Week as Calendar } from "@styled-icons/bootstrap/Calendar2Week";
import { CloseOutline as Close } from "@styled-icons/evaicons-outline/CloseOutline";
import { Checkmark } from "@styled-icons/evaicons-solid/Checkmark";
import { House } from "@styled-icons/bootstrap/House";
import { Tent } from "@styled-icons/fluentui-system-filled/Tent";
import { Caravan } from "@styled-icons/fa-solid/Caravan";

const AdminHeader = () => {
  const [notificationModal, setNotificationModal] = useState(false);
  const [notifications, setNotifications] = useState([]);

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const currentUser = useSelector(selectCurrentUser);

  const handleLogout = async () => {
    await dispatch(logout()).unwrap();
    navigate("/admin/login");
  };

  const getNotifications = async () => {
    setNotifications([]);

    try {
      axios
        .get(`${process.env.REACT_APP_API_BASE_URL}/admin.notifications.php`, {
          headers: {
            "X-API-KEY": process.env.REACT_APP_API_KEY,
          },
        })
        .then((response) => {
          const data = response.data.notifications;
          setNotifications(data);
        });
    } catch (error) {
      console.error(error);
    }
  };

  const deleteNotification = async (id) => {
    try {
      axios
        .delete(
          `${process.env.REACT_APP_API_BASE_URL}/admin.notifications.php?id=${id}`,
          {
            headers: {
              "X-API-KEY": process.env.REACT_APP_API_KEY,
            },
          }
        )
        .then(() => {
          getNotifications();
        });
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    getNotifications();
  }, []);

  useEffect(() => {
    if (notifications?.length === 0) {
      setNotificationModal(false);
    }
  }, [notifications]);

  const Notification = ({ data }) => {
    const { id, name, accommodation_id, message } = data;

    let accommodation;

    if (accommodation_id <= 12) {
      accommodation = `${accommodation_id}. mājiņa`;
      /* eslint-disable-next-line */
    } else if (accommodation_id == 13) {
      accommodation = `Telts vieta`;
    } else {
      accommodation = `Treilera vieta`;
    }

    return (
      <NotificationWrapper>
        <div className="notification">
          <div className="notification__left">
            <div className="notification__icon">
              {accommodation_id <= 12 && <House className="icon" />}
              {/* eslint-disable-next-line */}
              {accommodation_id == 13 && <Tent className="icon" />}
              {/* eslint-disable-next-line */}
              {accommodation_id == 14 && <Caravan className="icon" />}
            </div>

            <div className="notification__data">
              <h3 className="text-sm">{name}</h3>
              <p className="text-xs">
                {message} - {accommodation}
              </p>
            </div>
          </div>

          <button type="button" onClick={() => deleteNotification(id)}>
            <Checkmark className="icon delete-notification" />
          </button>
        </div>
      </NotificationWrapper>
    );
  };

  return (
    <Wrapper>
      <header className="header container-center">
        <div className="header__left">
          <Link to="/admin">
            <img src="/assets/small-icon.png" alt="panel-logo" />
          </Link>
        </div>

        <nav className="header__navigation__dekstop">
          <ul>
            <li>
              <NavLink to="/admin/dashboard">Panelis</NavLink>
            </li>

            <li>
              <NavLink to="/admin/calendar">Kalendārs</NavLink>
            </li>
          </ul>
        </nav>

        <div className="header__right">
          <Link target="_blank" to="https://mail.hostinger.com/">
            <div className="header__right__icon">
              <EmailOutline className="icon" />
            </div>
          </Link>

          <div className="header__right__icon">
            <Notifications
              className="icon"
              onClick={() => {
                notifications?.length > 0 && setNotificationModal(true);
              }}
            />
            {notifications?.length > 0 && (
              <>
                <div className="header__right__icon__notification-count">
                  <p className="text-xs">{notifications?.length}</p>
                </div>
              </>
            )}

            {notificationModal && (
              <>
                <motion.div
                  className="notification-container"
                  layout
                  initial={{ opacity: 0 }}
                  animate={{ opacity: 1 }}
                  exit={{ opacity: 0 }}
                  transition={{ duration: 0.4 }}
                >
                  <div className="notification-container__heading">
                    <h3>Paziņojumi</h3>
                    <Close
                      className="icon"
                      onClick={() => {
                        setNotificationModal(false);
                      }}
                    />
                  </div>

                  <ul className="notification-container__list">
                    {notifications?.map((notification) => {
                      const { id, name, accommodation_id, message } =
                        notification;

                      return (
                        <Notification
                          key={id}
                          data={{
                            id: id,
                            accommodation_id: accommodation_id,
                            name: name,
                            message: message,
                          }}
                        />
                      );
                    })}
                  </ul>
                </motion.div>
              </>
            )}
          </div>

          <div className="header__right__user-avatar">{currentUser?.email[0]}</div>

          <button
            type="button"
            className="header__right__sign-out"
            onClick={handleLogout}
          >
            <SignOut className="icon" />
          </button>
        </div>

        <MobileNavigation>
          <ul>
            <li>
              <NavLink to="/admin/dashboard">
                <Home className="icon" />
                <p className="text-sm">Panelis</p>
              </NavLink>
            </li>
            <li>
              <NavLink to="/admin/calendar">
                <Calendar className="icon" />
                <p className="text-sm">Kalendārs</p>
              </NavLink>
            </li>
          </ul>
        </MobileNavigation>
      </header>
    </Wrapper>
  );
};

const NotificationWrapper = styled.li`
  .notification {
    display: flex;
    gap: 8px;
    justify-content: space-between;
    align-items: flex-start;

    h3 {
      max-width: 150px;
      text-overflow: ellipsis;
      overflow: hidden;
      white-space: nowrap;
    }

    .delete-notification {
      cursor: pointer;
    }

    .delete-notification:hover {
      color: var(--green) !important;
    }

    &__left {
      display: flex;
      align-items: flex-start;
      gap: 8px;
    }

    &__icon {
      height: 28px;
      min-width: 28px;
      width: 28px;
      background-color: var(--grey);
      border-radius: 8px;
      display: flex;
      justify-content: center;
      align-items: center;

      .icon {
        max-width: 16px !important;
        min-width: 16px !important;
      }
    }
  }
`;

const MobileNavigation = styled.div`
  display: none;
  position: fixed;
  bottom: 0;
  left: 0;
  z-index: 100;
  width: 100%;
  background-color: var(--white);

  @media (max-width: 760px) {
    display: flex;
  }

  ul {
    width: 100%;
    display: flex;

    li {
      flex-grow: 1;

      a {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        padding: 12px 0 16px;
        gap: 8px;
        border-top: 5px solid transparent;

        .icon {
          max-width: 20px;
          min-width: 20px;
          width: 100%;
          color: var(--black);
        }
      }

      .active {
        border-color: var(--green);

        .icon {
          color: var(--green);
        }

        p {
          color: var(--green);
          font-weight: 600;
        }
      }
    }
  }
`;

const Wrapper = styled.div`
  box-shadow: rgba(0, 0, 0, 0.02) 0px 1px 3px 0px,
    rgba(27, 31, 35, 0.15) 0px 0px 0px 1px;
  background-color: var(--white);
  position: sticky;
  top: 0;
  z-index: 100;

  .header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 24px;
    padding: 8px 20px;

    &__navigation__dekstop {
      @media (max-width: 760px) {
        display: none;
      }
    }

    &__left {
      img {
        max-width: 60px;
        min-width: 60px;
        width: 100%;
        object-fit: contain;
      }
    }

    nav {
      ul {
        display: flex;

        li {
          a {
            white-space: nowrap;
            padding: 12px 16px;
            border-bottom: 3px solid transparent;
          }

          .active {
            border-bottom: 3px solid var(--green);
            color: var(--green) !important;
            font-weight: 500;
          }

          a:hover {
            color: var(--green) !important;
          }
        }
      }
    }

    &__right {
      display: flex;
      align-items: center;
      gap: 16px;

      &__icon {
        position: relative;

        .notification-container {
          position: absolute;
          bottom: -240px;
          right: 0;
          box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 3px 0px,
            rgba(27, 31, 35, 0.15) 0px 0px 0px 1px;
          padding: 8px;
          border-radius: 8px;
          background-color: var(--white);
          width: 250px;

          @media (max-width: 550px) {
            right: -50px;
          }

          &__list {
            width: 100%;
            height: 160px;
            overflow-y: scroll;
            display: flex;
            flex-direction: column;
            gap: 16px;

            -ms-overflow-style: none;
            scrollbar-width: none;

            ::-webkit-scrollbar {
              display: none;
            }
          }

          &__heading {
            display: flex;
            align-items: center;
            justify-content: space-between;
            margin: 0 0 16px 0;

            .icon {
              max-width: 24px;
              min-width: 24px;
              width: 100%;
              color: var(--black);
              padding: 5px;
              cursor: pointer;
            }
          }
        }

        &__notification-count {
          position: absolute;
          top: -5px;
          right: -5px;
          background-color: var(--green);
          width: 18px;
          height: 18px;
          border-radius: 50%;
          display: flex;
          align-items: center;
          justify-content: center;

          p {
            color: var(--white);
          }
        }

        .icon {
          color: var(--black);
          max-width: 24px;
          min-width: 24px;
          width: 100%;
          cursor: pointer;
        }
      }

      &__user-avatar {
        width: 32px;
        height: 32px;
        object-fit: cover;
        border-radius: 50%;
        background-color: var(--green);
        color: var(--white);
        font-weight: bold;
        display: flex;
        justify-content: center;
        align-items: center;
        text-transform: uppercase;
      }

      &__sign-out {
        .icon {
          color: var(--black);
          max-width: 24px;
          min-width: 24px;
          width: 100%;
          cursor: pointer;
        }
      }
    }
  }
`;

export default AdminHeader;
