//redux
import { selectTriggerContainerScrolled } from "../redux/appState";
import { useSelector } from "react-redux";


const useTriggerContainerScrolled = () => {
    const triggerContainerScrolled = useSelector(selectTriggerContainerScrolled);

  return triggerContainerScrolled;
};

export default useTriggerContainerScrolled;
