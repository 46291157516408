import React from "react";
import styled from "styled-components";

//components
import { Parallax } from "react-parallax";
import { Link } from "react-router-dom";

//locales
import { useTranslation } from "react-i18next";

const Hero = () => {
  const { t } = useTranslation();

  return (
    <Wrapper id="hero">
      <Parallax
        bgImage={"/assets/heros/home-hero.jpg"}
        bgImageAlt="hero-image"
        strength={200}
        style={{ width: "100vw" }}
      >
        <HeroText className="container-center hero">
          <div className="hero__text">
            <h1 className="text-5xl">
              {t("hero_heading1", { ns: "home" })}{" "}
              <span>{t("hero_heading2", { ns: "home" })}</span>{" "}
              <span>{t("hero_heading3", { ns: "home" })}</span>{" "}
              {t("hero_heading4", { ns: "home" })}
            </h1>
            <p className="text-lg">{t("hero_desc", { ns: "home" })}</p>
            <div style={{ display: "inline-block" }}>
              <Link className="button" to="/reserve">
                {t("reserve", { ns: "header" })}
              </Link>
            </div>
          </div>
        </HeroText>
      </Parallax>
    </Wrapper>
  );
};

const HeroText = styled.div`
  min-height: 650px;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;

  .hero {
    &__text {
      max-width: 520px;
      padding: 60px 20px 0;

      span {
        color: var(--green);
      }

      h1 {
        margin: 0 0 16px 0;
        color: var(--white);
        line-height: 3.5rem;

        @media screen and (max-width: 760px) {
          font-size: 2.25rem !important;
          line-height: 3rem;
        }
      }

      p {
        margin: 0 0 32px 0;
        color: var(--white);

        @media screen and (max-width: 760px) {
          margin: 24px 0 56px 0;
        }
      }
    }
  }
`;

const Wrapper = styled.section`
  background-color: var(--lightgrey);
`;

export default Hero;
