import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { Link } from "react-router-dom";

//locales
import { useTranslation } from "react-i18next";

const CookiesModal = () => {
  const { t } = useTranslation();
  const [cookiesModal, showCoookiesModal] = useState("");

  const allowCookies = () => {
    window.localStorage.setItem("cookies-allowed", true);
    showCoookiesModal(false);
  };

  useEffect(() => {
    const isCookiesAllowed = window.localStorage.getItem("cookies-allowed");

    if (!isCookiesAllowed) {
      showCoookiesModal(true);
    } else {
      showCoookiesModal(false);
    }
  }, []);

  return (
    <Wrapper>
      {cookiesModal && (
        <>
          <div className="cookies__modal__container">
            <h3 className="text-lg">
              {t("cookies_heading", { ns: "cookies" })}
            </h3>

            <p>{t("cookies_desc", { ns: "cookies" })}</p>

            <div className="cookies__modal__container__btns">
              <Link to="/privacy-policy">
                {t("cookies_privacy_policy", { ns: "cookies" })}
              </Link>
              <button className="button" onClick={() => allowCookies()}>
                {t("cookies_btn", { ns: "cookies" })}
              </button>
            </div>
          </div>
        </>
      )}
    </Wrapper>
  );
};

const Wrapper = styled.div`
  position: fixed;
  bottom: 20px;
  right: 20px;
  max-width: 500px;
  width: 100%;
  background-color: var(--white);
  border-radius: 8px;
  z-index: 90;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 12px;

  @media (max-width: 760px) {
    max-width: 100%;
    right: 0;
    bottom: 0;
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
  }

  .cookies__modal__container {
    padding: 16px;

    p {
      margin: 8px 0 16px 0;
    }

    &__btns {
      display: flex;
      align-items: center;
      justify-content: flex-end;
      flex-wrap: wrap;
      gap: 16px;

      a {
        color: var(--green);
        border-bottom: 1px solid var(--green);
      }

      a:hover {
        border-color: transparent;
      }
    }
  }
`;

export default CookiesModal;
