import React from "react";
import styled from "styled-components";

import { Link } from "react-router-dom";

const AdminFooter = () => {
  return (
    <Wrapper>
      Built with passion ⚡{" "}
      <Link target="blank_" to="https://ansisarthur.com">ansisarthur.com</Link>
      <p className='copyright'>© {new Date().getFullYear()}</p>
    </Wrapper>
  );
};

const Wrapper = styled.footer`
  margin: 0 auto;
  max-width: 300px;
  text-align: center;
  padding: 20px 0 120px;

  .copyright {
    margin: 8px 0 0 0;
  }

  a {
    text-decoration: underline;
    color: var(--green);
  }
`;

export default AdminFooter;
