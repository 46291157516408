import React from "react";
import styled from "styled-components";

import { Parallax } from "react-parallax";

const Hero = ({ data }) => {
  const { img, heading } = data;

  return (
    <Wrapper id="hero">
      <Parallax
        bgImage={img}
        bgImageAlt="hero-image"
        strength={200}
        style={{ width: "100vw" }}
      >
        <HeroText className="container-center">
          <div className="section__hero__text">
            <h1 className="text-3xl">{heading}</h1>
          </div>
        </HeroText>
      </Parallax>
    </Wrapper>
  );
};

const HeroText = styled.div`
  min-height: 300px;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;

  @media screen and (max-width: 650px) {
    min-height: 250px;
  }

  .section__hero__text {
    max-width: 400px;
    padding: 70px 0 0 0;

    h1 {
      font-weight: 600;
      color: var(--white);
      text-shadow: rgba(0, 0, 0, 0.2) 1px 5px 15px;
    }
  }
`;

const Wrapper = styled.section`
background-color: var(--lightgrey);
`;

export default Hero;
