import React, { useRef } from "react";
import styled from "styled-components";
import { motion, useInView } from "framer-motion";

import { Link } from "react-router-dom";

//locales
import { useTranslation } from "react-i18next";

const MainPoints = () => {
  const { t } = useTranslation();
  const ref = useRef(null);
  const isInView = useInView(ref, { once: true });

  const variants = {
    hidden: { opacity: 0, y: 50 },
    visible: { opacity: 1, y: 0, transition: { duration: 0.6 } },
  };

  return (
    <Wrapper
      id="main-points"
      ref={ref}
      initial="hidden"
      animate={isInView ? "visible" : "hidden"}
      variants={variants}
    >
      <div className="main__points container-center">
        <Link to="/houses" className="main__points__point">
          <img src="/assets/icons/house.svg" alt="point-icon" />
          <h2 className="text-lg">{t("main_point1", { ns: "home" })}</h2>
        </Link>

        <Link to="/tents" className="main__points__point">
          <img src="/assets/icons/tent.svg" alt="point-icon" />
          <h2 className="text-lg">{t("main_point2", { ns: "home" })}</h2>
        </Link>

        <Link to="/trailers" className="main__points__point">
          <img src="/assets/icons/trailer.svg" alt="point-icon" />
          <h2 className="text-lg">{t("main_point3", { ns: "home" })}</h2>
        </Link>

        <div className="main__points__point">
          <img src="/assets/icons/beach.svg" alt="point-icon" />
          <h2 className="text-lg">{t("main_point4", { ns: "home" })}</h2>
        </div>

        <div className="main__points__point">
          <img src="/assets/icons/animal.svg" alt="point-icon" />
          <h2 className="text-lg">{t("main_point5", { ns: "home" })}</h2>
        </div>

        <div className="main__points__point">
          <img src="/assets/icons/moon.svg" alt="point-icon" />
          <h2 className="text-lg">{t("main_point6", { ns: "home" })}</h2>
        </div>
      </div>
    </Wrapper>
  );
};

const Wrapper = styled(motion.section)`
  .main__points {
    display: grid;
    grid-template-columns: repeat(6, 1fr);
    grid-gap: 16px;
    padding: 60px 20px;

    @media screen and (max-width: 880px) {
      grid-template-columns: repeat(3, 1fr);
      grid-gap: 24px;
    }

    @media screen and (max-width: 600px) {
      grid-template-columns: repeat(2, 1fr);

      &__point {
        img {
          max-width: 48px !important;
          min-width: 48px !important;
        }
      }
    }

    &__point {
      display: flex;
      flex-direction: column;
      align-items: center;
      gap: 8px;

      img {
        max-width: 64px;
        min-width: 64px;
        width: 100%;
        object-fit: contain;
        position: relative;
      }

      h2 {
        font-weight: 600;
        color: var(--green);
        text-align: center;
      }
    }

    &__point:hover {
      img {
        bottom: 10px;
      }
    }
  }
`;

export default MainPoints;
