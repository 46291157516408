// src/i18n.js
import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import Backend from "i18next-http-backend";

i18n
  .use(Backend)
  .use(initReactI18next)
  .init({
    fallbackLng: "lv",
    lng: localStorage.getItem("language") || "lv",
    debug: false,
    interpolation: {
      escapeValue: false,
    },
    backend: {
      loadPath: "/locales/{{lng}}/{{ns}}.json",
    },
    ns: [
      "navigation",
      "header",
      "footer",
      "rules",
      "reserve",
      "pricing",
      "policy",
      "confirmation",
      "home",
      "reviews",
      "accommodations",
      "reservationform",
      "cookies"
    ],
  });

export default i18n;
