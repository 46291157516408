const reviewsList = [
  {
    id: 0,
    stars: 5,
    review: "review1",
    imageUrl:
      "/assets/reviews/linda.png",
    name: "Linda Stalmeistare",
  },

  {
    id: 1,
    stars: 5,
    review: "review2",
    imageUrl:
      "/assets/reviews/pamela.png",
    name: "Pamela Stepina",
  },

  {
    id: 2,
    stars: 5,
    review: "review3",
    imageUrl:
      "/assets/reviews/inna.png",
    name: "Inna Mironova",
  },

  {
    id: 3,
    stars: 5,
    review: "review4",
    imageUrl:
      "/assets/reviews/inese.png",
    name: "Inese Cernuho",
  },

  {
    id: 4,
    stars: 5,
    review: "review5",
    imageUrl:
      "/assets/reviews/gatis.png",
    name: "Gatis Kampernovs",
  },

  {
    id: 5,
    stars: 5,
    review: "review6",
    imageUrl:
      "/assets/reviews/kristiana.jpg",
    name: "Kristiāna Beķe",
  },

  {
    id: 6,
    stars: 5,
    review: "review7",
    imageUrl:
      "/assets/reviews/zane.jpg",
    name: "Dobrovoļska Zane",
  },

  {
    id: 7,
    stars: 5,
    review: "review8",
    imageUrl:
      "/assets/reviews/elina.jpg",
    name: "Elina Rastopčina",
  },

  {
    id: 8,
    stars: 5,
    review: "review9",
    imageUrl:
      "/assets/reviews/tanja.png",
    name: "Tanja Diederich",
  },
];

export default reviewsList;
