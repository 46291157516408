import React, { useState, useEffect } from "react";
import styled from "styled-components";
import axios from "axios";

//components
import {
  AreaChart,
  Area,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  ResponsiveContainer,
} from "recharts";
import Skeleton from "react-loading-skeleton";

//icons
import { KeyboardArrowUp as Arrow } from "@styled-icons/material/KeyboardArrowUp";

const SalesReport = ({ name, Icon }) => {
  const [selectedReport, setSelectedReport] = useState("Ienākumi");
  const [isReportOptionsOpened, setReportOptionsOpened] = useState(false);

  const handleReportOptionSelection = (option) => {
    setSelectedReport(option);
    setReportOptionsOpened(false);
  };

  const currentYear = new Date().getFullYear();
  const [selectedYear, setSelectedYear] = useState(currentYear);
  const [isYearSelectionOpened, setYearSelectionOpened] = useState(false);
  const [yearSelectionOptions, setYearSelectionOptions] = useState([]);

  const generateYearSelectionOptions = () => {
    const yearSelectionArray = [currentYear - 1, currentYear, currentYear + 1];
    setYearSelectionOptions(yearSelectionArray);
  };

  const handleSelectYear = (option) => {
    setSelectedYear(option);
    setYearSelectionOpened(false);
  };

  const [salesReportData, setSalesReportData] = useState([]);
  const [loading, setLoading] = useState(true);

  const getSalesReport = async (type, year) => {
    let typeQuery = "";

    if (type === "Ienākumi") {
      typeQuery = "revenue";
    } else {
      typeQuery = "reservation-count";
    }

    try {
      axios
        .get(
          `${process.env.REACT_APP_API_BASE_URL}/admin.sales-report.php?type=${typeQuery}&year=${year}`,
          {
            headers: {
              "X-API-KEY": process.env.REACT_APP_API_KEY,
            },
          }
        )
        .then((response) => {
          const data = response.data;
          setSalesReportData(data);
          setLoading(false);
        });
    } catch {
      console.error("No response");
    }
  };

  useEffect(() => {
    if (selectedReport && selectedYear) {
      getSalesReport(selectedReport, selectedYear);
    } else {
      getSalesReport("Ienākumi", currentYear);
    }

    //eslint-disable-next-line
  }, [selectedReport, selectedYear]);

  useEffect(() => {
    generateYearSelectionOptions();
    //eslint-disable-next-line
  }, []);

  return (
    <Wrapper>
      <WidgetTop>
        <div>
          <h4 className='text-lg'>{name}</h4>
        </div>

        <div className="icon-container">{<Icon className="icon" />}</div>
      </WidgetTop>
      {loading ? (
        <>
          <Skeleton height={300} />
        </>
      ) : (
        <>
          <ReportFilters>
            <ReportFilter>
              <Selection>
                <Selected
                  onClick={() => setReportOptionsOpened(!isReportOptionsOpened)}
                >
                  <h3>{selectedReport}</h3>
                  <Arrow
                    style={{
                      transform: isReportOptionsOpened
                        ? "rotate(180deg)"
                        : "rotate(0)",
                    }}
                    className="icon"
                  />
                </Selected>

                {isReportOptionsOpened && (
                  <>
                    <Options>
                      <div
                        className="option"
                        onClick={() => handleReportOptionSelection("Ienākumi")}
                      >
                        Ienākumi
                      </div>
                      <div
                        className="option"
                        onClick={() =>
                          handleReportOptionSelection("Rezervācijas")
                        }
                      >
                        Rezervācijas
                      </div>
                    </Options>
                  </>
                )}
              </Selection>
            </ReportFilter>

            <ReportFilter>
              <Selection>
                <Selected
                  onClick={() => setYearSelectionOpened(!isYearSelectionOpened)}
                >
                  <h3>{selectedYear}</h3>
                  <Arrow
                    style={{
                      transform: isYearSelectionOpened
                        ? "rotate(180deg)"
                        : "rotate(0)",
                    }}
                    className="icon"
                  />
                </Selected>

                {isYearSelectionOpened && (
                  <>
                    <Options>
                      {yearSelectionOptions?.map((year, i) => (
                        <div
                          key={i}
                          className="option"
                          onClick={() => handleSelectYear(year)}
                        >
                          {year}
                        </div>
                      ))}
                    </Options>
                  </>
                )}
              </Selection>
            </ReportFilter>
          </ReportFilters>

          <WidgetContent>
            <ResponsiveContainer width="100%" height="100%">
              <AreaChart
                width={500}
                height={400}
                data={salesReportData}
                margin={{
                  top: 10,
                  right: 30,
                  left: 0,
                  bottom: 0,
                }}
              >
                <CartesianGrid strokeDasharray="3 3" />
                <XAxis dataKey="name" />
                <YAxis />
                <Tooltip />
                <Area
                  type="monotone"
                  dataKey="mājiņas"
                  stackId="1"
                  stroke="var(--green)"
                  fill="var(--green)"
                />
                <Area
                  type="monotone"
                  dataKey="teltis"
                  stackId="1"
                  stroke="var(--darkgreen)"
                  fill="var(--darkgreen)"
                />
                <Area
                  type="monotone"
                  dataKey="treileri"
                  stackId="1"
                  stroke="var(--yellow)"
                  fill="var(--yellow)"
                />
              </AreaChart>
            </ResponsiveContainer>
          </WidgetContent>
        </>
      )}
    </Wrapper>
  );
};

const Options = styled.div`
  position: absolute;
  top: 50px;
  width: 100%;
  background-color: var(--white);
  z-index: 90;
  box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 3px 0px,
    rgba(27, 31, 35, 0.15) 0px 0px 0px 1px;
  border-radius: 8px;
  overflow: hidden;

  .option {
    padding: 12px 16px;
    cursor: pointer;
  }

  .option:hover {
    background-color: var(--lightgrey);
  }
`;

const Selected = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 3px 0px,
    rgba(27, 31, 35, 0.15) 0px 0px 0px 1px;
  border-radius: 8px;
  padding: 8px 16px;
  cursor: pointer;

  .icon {
    max-width: 24px;
    min-width: 24px;
    width: 100%;
  }
`;

const Selection = styled.div`
  position: relative;
`;

const ReportFilter = styled.div``;

const ReportFilters = styled.div`
  margin: 0 0 16px 0;
  width: 100%;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: 16px;
`;

const WidgetContent = styled.div`
  width: 100%;
  height: 300px;

  .recharts-text {
    font-size: 0.875rem; /* 14px */
    line-height: 1.25rem; /* 20px */
  }

  .recharts-surface {
    border-radius: 8px;
    font-size: 0.875rem; /* 14px */
    line-height: 1.25rem; /* 20px */
  }
`;

const WidgetTop = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  gap: 24px;
  margin: 0 0 16px 0;

  p {
    color: var(--grey);
  }

  .icon-container {
    padding: 5px 8px 8px;
    background-color: var(--green);
    border-radius: 8px;

    .icon {
      min-width: 18px;
      max-width: 18px;
      width: 100%;
      color: var(--white);
    }
  }
`;

const Wrapper = styled.div`
  box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 3px 0px,
    rgba(27, 31, 35, 0.15) 0px 0px 0px 1px;
  padding: 16px;
  border-radius: 8px;
`;

export default SalesReport;
