import { isSameDay } from "date-fns";
import generateDisabledDates from "./useGenerateDisabledDates";

const isTodayDisabled = () => {
  const currentDate = new Date();

  const disabledDates = generateDisabledDates(); 
  return disabledDates.some((date) => isSameDay(date, currentDate));
};

export default isTodayDisabled;


