import React, { useState, useEffect } from "react";
import styled from "styled-components";
import axios from "axios";
import { motion } from "framer-motion";

//components
import PageHeading from "../../components/admin/PageHeading";
import CalendarWidgets from "../../components/admin/calendar/CalendarWidgets";
import Skeleton from "react-loading-skeleton";
import {
  Calendar as ReservationCalendar,
  momentLocalizer,
} from "react-big-calendar";
import { toast } from "react-toastify";
import moment from "moment";
import "moment/locale/lv";

//icons
import { Today } from "@styled-icons/material-rounded/Today";
import { KeyboardArrowLeft } from "@styled-icons/material/KeyboardArrowLeft";
import { KeyboardArrowRight } from "@styled-icons/material/KeyboardArrowRight";
import { House } from "@styled-icons/bootstrap/House";
import { Tent } from "@styled-icons/fluentui-system-filled/Tent";
import { Caravan as Trailer } from "@styled-icons/fa-solid/Caravan";
import { Plus } from "@styled-icons/bootstrap/Plus";
import { Create } from "@styled-icons/ionicons-outline/Create";
import { CloseOutline as Close } from "@styled-icons/evaicons-outline/CloseOutline";

//date range
import { DateRangePicker } from "react-date-range";
import { lv } from "date-fns/locale";
import { format } from "date-fns";

//data
import { accommodationData } from "../../data/accommodationData";

moment.locale("lv");

const Calendar = () => {
  const localizer = momentLocalizer(moment);
  const [reservationData, setReservationData] = useState([]);
  const [reservationDataFiltered, setReservationDataFiltered] = useState([]);
  const [selectedReservationFilter, setSelectedReservationFilter] =
    useState(null);
  const [statistics, setStatistics] = useState({
    arrivals: [],
    departures: [],
    stayover: [],
  });

  const [loading, setLoading] = useState(true);

  const getReservationData = async () => {
    setReservationData([]);

    try {
      axios
        .get(`${process.env.REACT_APP_API_BASE_URL}/admin.reservations.php`, {
          headers: {
            "X-API-KEY": process.env.REACT_APP_API_KEY,
          },
        })
        .then((response) => {
          const data = response.data;
          setStatistics({
            arrivals: data?.arrivals,
            departures: data?.departures,
            stayover: data?.stayover,
          });

          setReservationData(data?.reservations);
          setSelectedReservationFilter("houses");

          setTimeout(() => {
            setLoading(false);
          }, [1000]);
        });
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    getReservationData();
  }, []);

  useEffect(() => {
    if (reservationData?.length > 0 && selectedReservationFilter !== null) {
      let filterReservationData;

      if (selectedReservationFilter === "houses") {
        filterReservationData = reservationData.filter(
          (reservation) => reservation?.accommodation_id <= 12
        );
      } else if (selectedReservationFilter === "other") {
        filterReservationData = reservationData.filter(
          (reservation) => reservation?.accommodation_id > 12
        );
      }

      setReservationDataFiltered(filterReservationData);
    }
  }, [reservationData, selectedReservationFilter]);

  const [modal, setModal] = useState({ open: false, type: "none" });

  //calendar
  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(new Date());
  const [price, setPrice] = useState(0);

  const defaultReservationData = {
    accommodationId: 1,
    persons: 1,
    checkInDate: format(startDate, "yyyy-MM-dd"),
    checkOutDate: format(endDate, "yyyy-MM-dd"),
    status: "Confirmed",
    totalPrice: 0,
    totalNights: 0,
    name: "",
    email: "",
    number: "",
    note: "",
    sendConfirmationEmail: false,
  };

  const [reservation, setReservation] = useState(defaultReservationData);

  const handleSelect = (ranges) => {
    const newStartDate = ranges.selection.startDate;
    const newEndDate = ranges.selection.endDate;

    setStartDate(newStartDate);
    setEndDate(newEndDate);

    setReservation((reservationData) => ({
      ...reservationData,
      checkInDate: format(newStartDate, "yyyy-MM-dd"),
      checkOutDate: format(newEndDate, "yyyy-MM-dd"),
      checkInDateConfirmed: format(newStartDate, "dd.MM.yyyy"),
      checkOutDateConfirmed: format(newEndDate, "dd.MM.yyyy"),
    }));
  };

  const selectionRange = {
    startDate: startDate,
    endDate: endDate,
    key: "selection",
  };

  const handleTotalNightsCount = () => {
    const millisecondsInDay = 86400000;
    const daysDifference = (endDate - startDate) / millisecondsInDay;
    const selectedDatesCount = Math.abs(daysDifference);

    if (selectedDatesCount > 0) {
      setReservation((reservationData) => ({
        ...reservationData,
        totalPrice: selectedDatesCount * price,
        totalNights: selectedDatesCount,
      }));
    } else {
      setReservation((reservationData) => ({
        ...reservationData,
        totalPrice: 0,
        totalNights: 0,
      }));
    }
  };

  useEffect(() => {
    setPrice(accommodationData[reservation?.accommodationId - 1]?.price);
  }, [reservation?.accommodationId]);

  useEffect(() => {
    if (modal?.type === "create") {
      handleTotalNightsCount();
    }
    //eslint-disable-next-line
  }, [startDate, endDate, reservation?.accommodationId]);

  //calendar event block
  const CustomEvent = ({ event }) => {
    let eventName;

    if (event.accommodation_id < 13) {
      eventName = `${event.accommodation_id}. mājiņa`;
    } else if (event.accommodation_id >= 13 && event.accommodation_id < 14) {
      eventName = `Telts vieta`;
    } else if (event.accommodation_id >= 14) {
      eventName = `Treilera vieta`;
    }

    let backgroundColor;
    let textColor;

    if (event.status === "Pending") {
      backgroundColor = "var(--grey)";
      textColor = "var(--black)";
    } else if (event.status === "Confirmed") {
      backgroundColor = "var(--blue)";
      textColor = "var(--white)";
    } else if (event.status === "Completed") {
      backgroundColor = "var(--green)";
      textColor = "var(--white)";
    }

    return (
      <div
        style={{
          backgroundColor: backgroundColor,
          padding: "10px 12px",
          borderRadius: 8,
        }}
        onClick={() => {
          setModal({ open: true, type: "update" });

          setReservation({
            bookingId: event.booking_id,
            accommodationId: event.accommodation_id,
            persons: event.persons,
            checkInDate: new Date(event.check_in_date),
            checkOutDate: new Date(event.check_out_date),
            checkInDateConfirmed: new Date(event.check_in_date),
            checkOutDateConfirmed: new Date(event.check_out_date),
            status: event.status,
            totalPrice: event.total_price,
            name: event.name,
            email: event.email,
            number: event.phone_number,
            note: event.notes,
            sendConfirmationEmail: false,
          });

          setStartDate(new Date(event.check_in_date));
          setEndDate(new Date(event.check_out_date));
        }}
      >
        <h3 className="text-xs" style={{ color: textColor }}>
          {eventName}
        </h3>
        <p
          className="text-sm event-details"
          style={{
            color: textColor,
            maxWidth: "95%",
            overflow: "hidden",
            textOverflow: "ellipsis",
            whiteSpace: "pre-wrap",
          }}
        >
          {event.name}
        </p>
      </div>
    );
  };

  const createReservation = async (e) => {
    e.preventDefault();

    try {
      axios
        .post(
          `${process.env.REACT_APP_API_BASE_URL}/admin.reservations.php`,
          reservation,
          {
            headers: {
              "X-API-KEY": process.env.REACT_APP_API_KEY,
            },
          }
        )
        .then(async () => {
          await getReservationData().then(() => {
            setStartDate(new Date());
            setEndDate(new Date());
            setModal({ open: false, type: "none" });
            setReservation(defaultReservationData);

            const body = document.querySelector("body");
            body.style.overflowY = "scroll";

            toast.success("🐧 Izveidota jauna rezervācija!");
          });
        });
    } catch (error) {
      console.log(error);
    }
  };

  const updateReservation = async (e) => {
    e.preventDefault();

    const reservationData = {
      bookingId: reservation?.bookingId,
      accommodationId: reservation?.accommodationId,
      persons: reservation?.persons,
      checkInDate: format(startDate, "yyyy-MM-dd"),
      checkOutDate: format(endDate, "yyyy-MM-dd"),
      checkInDateConfirmed: format(startDate, "dd.MM.yyyy"),
      checkOutDateConfirmed: format(endDate, "dd.MM.yyyy"),
      status: reservation?.status,
      totalPrice: reservation?.totalPrice,
      name: reservation?.name,
      email: reservation?.email,
      number: reservation?.number,
      note: reservation?.note,
      sendConfirmationEmail: reservation?.sendConfirmationEmail,
    };

    try {
      setModal({ open: false, type: "none" });

      axios
        .put(
          `${process.env.REACT_APP_API_BASE_URL}/admin.reservations.php`,
          reservationData,
          {
            headers: {
              "X-API-KEY": process.env.REACT_APP_API_KEY,
            },
          }
        )
        .then(async () => {
          await getReservationData().then(() => {
            setStartDate(new Date());
            setEndDate(new Date());
            setReservation(defaultReservationData);

            toast.success("🐈 Atjaunoti rezervācijas dati!");
          });
        });
    } catch (error) {
      console.log(error);
    }
  };

  const deleteReservation = async (id) => {
    setModal({ open: false, type: "none" });
    //close modal instantly, in case it takes more then 1-2 secs to delete

    try {
      axios
        .delete(
          `${process.env.REACT_APP_API_BASE_URL}/admin.reservations.php?id=${id}`,
          {
            headers: {
              "X-API-KEY": process.env.REACT_APP_API_KEY,
            },
          }
        )
        .then(async () => {
          await getReservationData().then(() => {
            setStartDate(new Date());
            setEndDate(new Date());
            setReservation(defaultReservationData);
            toast.success("🗑️ Rezervācija dzēsta!");
          });
        });
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <Wrapper className="container-center">
      <PageHeading text={"Viss par un ap rezervācijām, vienā vietā"} />

      {loading ? (
        <>
          <Skeleton height={300} />
          <Skeleton height={500} style={{ marginTop: 40 }} />
        </>
      ) : (
        <>
          <CalendarWidgets data={statistics} />
          <CalendarSwitch>
            <CalendarSwitchBtns>
              <CalendarSwitchBtn
                onClick={() => setSelectedReservationFilter("houses")}
                className={selectedReservationFilter === "houses" && "active"}
              >
                <House className="icon" />
              </CalendarSwitchBtn>
              <CalendarSwitchBtn
                onClick={() => setSelectedReservationFilter("other")}
                className={selectedReservationFilter === "other" && "active"}
              >
                <Tent className="icon" />
                <Plus className="icon" />
                <Trailer className="icon" />
              </CalendarSwitchBtn>

              <CalendarSwitchBtn
                onClick={() => {
                  setModal({ open: true, type: "create" });
                  const body = document.querySelector("body");
                  body.style.overflow = "hidden";
                }}
              >
                <Create className="icon" />
              </CalendarSwitchBtn>
            </CalendarSwitchBtns>
          </CalendarSwitch>

          <ReservationCalendar
            localizer={localizer}
            events={reservationDataFiltered}
            startAccessor="check_in_date"
            endAccessor={(event) => {
              const endDate = new Date(event.check_out_date);
              endDate.setHours(23, 59, 59, 999);
              return endDate;
            }}
            style={{ height: "3000px" }}
            components={{ event: CustomEvent, toolbar: CustomToolbar }}
            views={["month"]}
            step={60}
            timeslots={1}
          />
        </>
      )}

      {modal?.open && (
        <>
          <CalendarModal
            layout
            initial={{ opacity: 0, x: "100%" }}
            animate={{ opacity: 1, x: 0 }}
            exit={{ opacity: 0, x: "100%" }}
            transition={{ duration: 0.4 }}
          >
            {modal?.type === "create" && (
              <>
                <CreateReservationModal onSubmit={createReservation}>
                  <div className="calendar-modal__heading">
                    <h2>Izveidot rezervāciju</h2>
                    <Close
                      className="icon"
                      onClick={() => {
                        setModal({ open: false, type: "none" });
                        const body = document.querySelector("body");
                        body.style.overflowY = "scroll";
                        setReservation(defaultReservationData);
                      }}
                    />
                  </div>

                  <div className="form__elem">
                    <label htmlFor="name">Vieta</label>
                    <div className="form__elem__input">
                      <select
                        id="status"
                        style={{ width: "100%" }}
                        className="text-base"
                        value={reservation?.accommodationId}
                        onChange={(e) => {
                          setReservation((prevState) => ({
                            ...prevState,
                            accommodationId: parseInt(e.target.value),
                          }));
                        }}
                      >
                        <option value={1}>1. mājiņa</option>
                        <option value={2}>2. mājiņa</option>
                        <option value={3}>3. mājiņa</option>
                        <option value={4}>4. mājiņa</option>
                        <option value={5}>5. mājiņa</option>
                        <option value={6}>6. mājiņa</option>
                        <option value={7}>7. mājiņa</option>
                        <option value={8}>8. mājiņa</option>
                        <option value={9}>9. mājiņa</option>
                        <option value={10}>10. mājiņa</option>
                        <option value={11}>11. mājiņa</option>
                        <option value={12}>12. mājiņa</option>
                        <option value={13}>Telts vieta</option>
                        <option value={14}>Treilera vieta</option>
                      </select>
                    </div>
                  </div>

                  <div className="form__elem">
                    <label htmlFor="name">Uzturēšanās laiks*</label>
                    <div className="form__elem__input">
                      <DateRangePicker
                        ranges={[selectionRange]}
                        weekStartsOn={1}
                        showMonthAndYearPickers={false}
                        locale={lv}
                        rangeColors={["var(--green)"]}
                        minDate={new Date()}
                        onChange={handleSelect}
                        id="date-range"
                      />
                    </div>
                  </div>

                  <div className="form__elem">
                    <label htmlFor="name">Vārds Uzvārds*</label>
                    <div className="form__elem__input">
                      <input
                        id="name"
                        type="text"
                        name="name"
                        value={reservation?.name}
                        onChange={(e) =>
                          setReservation((reservationData) => ({
                            ...reservationData,
                            name: e.target.value,
                          }))
                        }
                        maxLength={50}
                        required
                      />
                    </div>
                  </div>

                  <div className="form__elem">
                    <label htmlFor="number">Tālruņa Nr.*</label>

                    <div className="form__elem__input">
                      <input
                        id="number"
                        type="number"
                        name="number"
                        pattern="[0-9]*"
                        inputMode="numeric"
                        value={reservation?.number}
                        onChange={(e) =>
                          setReservation((reservationData) => ({
                            ...reservationData,
                            number: e.target.value,
                          }))
                        }
                        maxLength={50}
                        required
                      />
                    </div>
                  </div>

                  <ReservationTotal>
                    {reservation?.totalNights > 0 && (
                      <>
                        <div className="total__top">
                          <div className="total__top__arrival">
                            <h4 className="text-sm">Iebraukšana</h4>
                            <p className="date text-xl">
                              {format(startDate, "dd.MM.yyyy")}
                            </p>
                            <p className="arrival-time">14:00 - 21:00</p>
                          </div>

                          <div className="total__top__departure">
                            <h4 className="text-sm">Izbraukšana</h4>
                            <p className="date text-xl">
                              {format(endDate, "dd.MM.yyyy")}
                            </p>
                            <p className="departure-time">Līdz 12:00</p>
                          </div>
                        </div>
                      </>
                    )}

                    <div className="total__bottom">
                      <div className="total__bottom__item">
                        <h3 className="text-xl">Cena</h3>
                        <p className="text-xl">€ {price}</p>
                      </div>

                      {reservation?.totalNights > 0 && (
                        <>
                          <div className="total__bottom__item">
                            <h3 className="text-xl">Kopā</h3>
                            <p className="text-xl">
                              € {reservation?.totalPrice}
                            </p>
                          </div>
                        </>
                      )}
                    </div>

                    <p style={{ marginTop: 20 }}>
                      {" "}
                      Ja rāda nepareizu kopējo summu, izvēlies uzturēšanos
                      datumus vēlreiz, cena tiks pārrēķināta 🐧
                    </p>
                  </ReservationTotal>

                  <div className="form__elem" style={{ marginBottom: 200 }}>
                    <button type="submit" className="submit-button">
                      Izveidot rezervāciju
                    </button>
                  </div>
                </CreateReservationModal>
              </>
            )}

            {modal?.type === "update" && (
              <>
                <CreateReservationModal onSubmit={updateReservation}>
                  <div className="calendar-modal__heading">
                    <h2>Atjaunot rezervācijas datus</h2>
                    <Close
                      className="icon"
                      onClick={() => {
                        setModal({ open: false, type: "none" });
                        const body = document.querySelector("body");
                        body.style.overflowY = "scroll";
                        setReservation(defaultReservationData);
                      }}
                    />
                  </div>

                  <div className="form__elem">
                    <label htmlFor="name">Vieta*</label>
                    <div className="form__elem__input">
                      <select
                        id="status"
                        style={{ width: "100%" }}
                        className="text-base"
                        value={reservation?.accommodationId}
                        onChange={(e) => {
                          setReservation((prevState) => ({
                            ...prevState,
                            accommodationId: parseInt(e.target.value),
                          }));
                        }}
                      >
                        <option value={1}>1. mājiņa</option>
                        <option value={2}>2. mājiņa</option>
                        <option value={3}>3. mājiņa</option>
                        <option value={4}>4. mājiņa</option>
                        <option value={5}>5. mājiņa</option>
                        <option value={6}>6. mājiņa</option>
                        <option value={7}>7. mājiņa</option>
                        <option value={8}>8. mājiņa</option>
                        <option value={9}>9. mājiņa</option>
                        <option value={10}>10. mājiņa</option>
                        <option value={11}>11. mājiņa</option>
                        <option value={12}>12. mājiņa</option>
                        <option value={13}>Telts vieta</option>
                        <option value={14}>Treilera vieta</option>
                      </select>
                    </div>
                  </div>

                  <div className="form__elem">
                    <label htmlFor="name">Statuss*</label>
                    <div className="form__elem__input">
                      <select
                        id="status"
                        style={{ width: "100%" }}
                        className="text-base"
                        onChange={(e) =>
                          setReservation((prevState) => ({
                            ...prevState,
                            status: e.target.value,
                          }))
                        }
                      >
                        {reservation?.status && (
                          <option value={reservation?.status}>
                            {reservation?.status === "Pending" &&
                              "Neapstiprināts"}
                            {reservation?.status === "Confirmed" &&
                              "Apstiprināts"}
                            {reservation?.status === "Completed" && "Pabeigts"}
                          </option>
                        )}

                        {/* Render the options excluding the current status */}
                        {reservation?.status !== "Pending" && (
                          <option value="Pending">Neapstiprināts</option>
                        )}
                        {reservation?.status !== "Confirmed" && (
                          <option value="Confirmed">Apstiprināts</option>
                        )}
                        {reservation?.status !== "Completed" && (
                          <option value="Completed">Pabeigts</option>
                        )}
                      </select>
                    </div>
                  </div>

                  <div className="form__elem">
                    <label htmlFor="name">Uzturēšanās laiks*</label>
                    <div className="form__elem__input">
                      <DateRangePicker
                        ranges={[selectionRange]}
                        weekStartsOn={1}
                        showMonthAndYearPickers={false}
                        locale={lv}
                        rangeColors={["var(--green)"]}
                        minDate={new Date()}
                        onChange={handleSelect}
                        id="date-range"
                      />
                    </div>
                  </div>

                  <div className="form__elem">
                    <label htmlFor="name">Vārds Uzvārds*</label>
                    <div className="form__elem__input">
                      <input
                        id="name"
                        type="text"
                        name="name"
                        value={reservation?.name}
                        placeholder={reservation?.name}
                        onChange={(e) =>
                          setReservation((reservationData) => ({
                            ...reservationData,
                            name: e.target.value,
                          }))
                        }
                        maxLength={50}
                        required
                      />
                    </div>
                  </div>

                  <div className="form__elem">
                    <label htmlFor="number">Tālruņa Nr.*</label>

                    <div className="form__elem__input">
                      <input
                        id="number"
                        type="number"
                        name="number"
                        pattern="[0-9]*"
                        inputMode="numeric"
                        placeholder={reservation?.number}
                        value={reservation?.number}
                        onChange={(e) =>
                          setReservation((reservationData) => ({
                            ...reservationData,
                            number: e.target.value,
                          }))
                        }
                        maxLength={50}
                        required
                      />
                    </div>
                  </div>

                  <div className="form__elem">
                    <label htmlFor="email">E-pasts</label>

                    <div className="form__elem__input">
                      <input
                        id="email"
                        type="email"
                        name="email"
                        value={reservation?.email}
                        placeholder={reservation?.email}
                        onChange={(e) =>
                          setReservation((reservationData) => ({
                            ...reservationData,
                            email: e.target.value,
                          }))
                        }
                        maxLength={50}
                      />
                    </div>

                    {reservation?.status === "Confirmed" && (
                      <>
                        <label
                          className="checkmark-container"
                          style={{ marginTop: 16 }}
                        >
                          <p
                            className="text-sm"
                            style={{ position: "relative", top: 3 }}
                          >
                            Nosūtīt apstiprinājuma e-pastu
                          </p>
                          <input
                            onChange={() =>
                              setReservation((data) => ({
                                ...data,
                                sendConfirmationEmail:
                                  !data?.sendConfirmationEmail,
                              }))
                            }
                            type="checkbox"
                            checked={reservation?.sendConfirmationEmail}
                          />
                          <span className="checkmark"></span>
                        </label>
                      </>
                    )}
                  </div>

                  <div className="form__elem">
                    <label htmlFor="number">Cena, €</label>

                    <div className="form__elem__input">
                      <input
                        type="number"
                        name="number"
                        pattern="[0-9]*"
                        inputMode="numeric"
                        value={reservation?.totalPrice}
                        placeholder={reservation?.totalPrice}
                        onChange={(e) =>
                          setReservation((reservationData) => ({
                            ...reservationData,
                            totalPrice: e.target.value,
                          }))
                        }
                        maxLength={50}
                        required
                      />
                    </div>
                  </div>

                  <div className="form__elem">
                    <label htmlFor="number">Piezīmes</label>

                    <div className="form__elem__input">
                      <textarea
                        placeholder="Komentārs"
                        onChange={(e) =>
                          setReservation((prevState) => ({
                            ...prevState,
                            note: e.target.value,
                          }))
                        }
                        value={reservation?.note}
                      />
                    </div>

                    <p className="note text-sm">
                      {reservation?.status !== "Completed" && (
                        <>
                          ✅ Komentāra aili vari izmantot saviem pierakstiem, tā
                          netiek nosūtīta rezervācijas apstiprinājumā.
                        </>
                      )}
                    </p>
                  </div>

                  <div
                    className="form__elem"
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      gap: 16,
                      marginBottom: 200,
                    }}
                  >
                    <button type="submit" className="submit-button">
                      Atjaunot rezervāciju
                    </button>

                    <button
                      type="button"
                      className="delete-button"
                      onClick={() => {
                        const confirmed = window.confirm(
                          "Vai tiešām izdzēst rezervāciju? Rezervācija un tās dati tiks dzēsta neatgriezeniski"
                        );
                        if (confirmed) {
                          deleteReservation(reservation?.bookingId);
                        }
                      }}
                    >
                      Dzēst rezervāciju
                    </button>
                  </div>
                </CreateReservationModal>
              </>
            )}
          </CalendarModal>
        </>
      )}
    </Wrapper>
  );
};

const ReservationTotal = styled.div`
  width: 100%;
  border-top: 1px dashed var(--lightgrey);
  border-bottom: 1px dashed var(--lightgrey);
  padding: 16px 0;

  .total__top {
    display: flex;
    justify-content: space-between;
    gap: 16px;
    flex-wrap: wrap;
    margin: 0 0 16px 0;

    h4 {
      font-weight: 400;
    }

    .date {
      margin: 4px 0 0;
    }

    .departure-time,
    .arrival-time {
      color: var(--grey);
    }
  }

  .total__bottom {
    display: flex;
    flex-direction: column;
    margin: 8px 0 0 0;
    gap: 16px;

    &__item {
      display: flex;
      align-items: center;
      justify-content: space-between;
      gap: 16px;
      flex-wrap: wrap;
    }

    h3,
    p {
      font-weight: 500;
    }
  }
`;

const CreateReservationModal = styled.form`
  display: flex;
  flex-direction: column;
  gap: 16px;
  padding: 24px;
`;

const CalendarModal = styled(motion.div)`
  max-width: 400px;
  min-width: 250px;
  max-height: 100vh;
  width: 100% !important;
  height: 100% !important;
  position: fixed;
  z-index: 120;
  top: 0;
  right: 0;
  box-shadow: rgba(0, 0, 0, 0.05) 0px 6px 24px 0px,
    rgba(0, 0, 0, 0.08) 0px 0px 0px 1px;
  background-color: var(--white);
  overflow-y: scroll;

  @media (max-width: 650px) {
    width: 100%;
    height: 100vh !important;
    z-index: 150;
    left: 0;
    max-width: unset;
  }

  .calendar-modal__heading {
    display: flex;
    align-items: center;
    justify-content: space-between;

    .icon {
      max-width: 24px;
      min-width: 24px;
      width: 100%;
      color: var(--black);
      padding: 5px;
      cursor: pointer;
    }
  }

  .form__elem {
    width: 100%;

    .checkmark-container input:checked ~ .checkmark:after {
      display: block;
    }

    .checkmark-container input:checked ~ .checkmark {
      background-color: var(--green);
    }

    .checkmark-container:hover input ~ .checkmark {
      background-color: var(--green);
    }

    .checkmark-container {
      display: block;
      position: relative;
      padding-left: 35px;
      margin-bottom: 12px;
      cursor: pointer;
      font-size: 0.9rem;
      -webkit-user-select: none;
      -moz-user-select: none;
      -ms-user-select: none;
      user-select: none;

      p,
      a {
        font-weight: 400;
      }

      a {
        text-decoration: underline;
      }

      .checkmark:after {
        content: "";
        position: absolute;
        display: none;
        left: 8px;
        top: 3px;
        width: 7px;
        height: 12px;
        border: solid white;
        border-width: 0 3px 3px 0;
        -webkit-transform: rotate(45deg);
        -ms-transform: rotate(45deg);
        transform: rotate(45deg);
      }

      .checkmark {
        position: absolute;
        top: 0;
        left: 0;
        height: 25px;
        width: 25px;
        border-radius: 8px;
        background-color: var(--grey);
      }

      input {
        position: absolute;
        opacity: 0;
        cursor: pointer;
        height: 0;
        width: 0;
      }
    }

    .rdrDateRangePickerWrapper {
      width: 100%;
      font-size: 0.9rem !important;

      .rdrDefinedRangesWrapper {
        display: none;
      }

      .rdrDateDisplayWrapper {
        border-radius: 8px;
        background-color: transparent;

        .rdrDateDisplay {
          color: var(--green) !important;
          margin: 0;

          .rdrDateDisplayItem {
            border-radius: 8px;
          }

          input {
            border: none;
            border-radius: 8px;
          }
        }
      }

      .rdrCalendarWrapper {
        width: 100%;
        color: var(--green);

        .rdrMonthsVertical {
          flex-direction: row;
        }

        .rdrMonth {
          max-width: 100%;
          width: 100% !important;
          padding: 0;

          .rdrWeekDay {
            font-size: 0.875rem !important;
          }
        }

        .rdrDayDisabled .rdrDayNumber span {
          color: var(--grey);
        }
      }
    }

    .submit-button {
      padding: 8px 24px;
      background: var(--green);
      color: var(--white);
      border-radius: 8px;
      transition: 0.3s ease-in-out;
      width: 100%;
      font-size: 1rem;
      cursor: pointer;
    }

    .delete-button {
      padding: 8px 24px;
      background: var(--white);
      color: var(--alert);
      border-radius: 8px;
      border: 1px solid var(--alert);
      transition: 0.3s ease-in-out;
      width: 100%;
      font-size: 1rem;
      cursor: pointer;
    }

    .submit-button:disabled {
      background-color: var(--grey);
    }

    .note {
      color: var(--black);
      margin: 8px 0 0 0;
    }

    h3,
    label {
      color: var(--green);
      font-weight: 600;
      font-size: 1rem;
    }

    &__input {
      margin: 8px 0 0 0;

      input,
      textarea,
      select {
        min-width: 100%;
        padding: 8px 16px;
        border: 1px solid var(--grey);
        outline: none;
        border-radius: 8px;
      }

      input[type="number"]::-webkit-inner-spin-button,
      input[type="number"]::-webkit-outer-spin-button {
        -webkit-appearance: none;
        margin: 0;
      }

      textarea {
        resize: none;
        min-height: 150px;
      }
    }

    &__input__persons {
      display: flex;
      justify-content: space-between;
      margin: 8px 0 0 0;

      &__toggle {
        display: flex;
        gap: 16px;

        button {
          width: 32px;
          height: 32px;
          border-radius: 50%;
          cursor: pointer;
          transition: 0.3s ease-in-out;
          font-size: 1.5rem;
          display: flex;
          align-items: center;
          justify-content: center;

          p {
            font-size: 1.87rem;
            position: relative;
            bottom: 1px;
          }
        }
      }
    }
  }
`;

const CalendarSwitchBtn = styled.div`
  cursor: pointer;
  display: inline-block;
  margin: 0;
  text-align: center;
  vertical-align: middle;
  background: none;
  background-image: none;
  padding: 10px;
  line-height: normal;
  white-space: nowrap;
  flex-grow: 1;

  .icon {
    max-width: 18px;
    width: 100%;
  }

  p {
    font-size: 0.9rem;
  }
`;

const CalendarSwitchBtns = styled.div`
  border-radius: 8px;
  display: flex;
  width: 100%;
  background-color: var(--white);
  overflow: hidden;

  .active {
    background-color: var(--green);
    color: var(--white);
  }
`;

const CalendarSwitch = styled.div`
  display: flex;
  justify-content: center;
  position: fixed;
  z-index: 100;
  bottom: 80px;
  left: 50%;
  transform: translateX(-50%);
  box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 3px 0px,
    rgba(27, 31, 35, 0.15) 0px 0px 0px 1px;
  border-radius: 8px;

  @media only screen and (max-width: 650px) {
    left: 20px;
    transform: unset;
    bottom: 100px;
  }
`;

const CustomToolbar = ({ label, onNavigate }) => (
  <div className="rbc-toolbar">
    <span className="rbc-btn-group" style={{ display: "flex" }}>
      <button
        type="button"
        style={{ flex: "1 0 0" }}
        onClick={() => onNavigate("TODAY")}
      >
        <Today className="icon" />
      </button>
      <button
        type="button"
        style={{ flex: "1 0 0" }}
        onClick={() => onNavigate("PREV")}
      >
        <KeyboardArrowLeft className="icon" />
      </button>
      <button
        type="button"
        style={{ flex: "1 0 0" }}
        onClick={() => onNavigate("NEXT")}
      >
        <KeyboardArrowRight className="icon" />
      </button>
    </span>
    <span className="rbc-toolbar-label">{label}</span>
  </div>
);

const Wrapper = styled.section`
  padding: 0 20px 40px;

  /* calendar style */
  .rbc-calendar {
    width: 100%;
    margin-bottom: 40px;

    .rbc-row-content {
      overflow-y: scroll;
      height: 100%;

      .rbc-row:nth-child(1) {
        position: sticky;
        top: 0;
        left: 0;
        background-color: var(--white);
        border-bottom: 1px solid var(--grey);
      }
    }

    .rbc-month-view {
      box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 3px 0px,
        rgba(27, 31, 35, 0.15) 0px 0px 0px 1px;
      border-radius: 8px;
      border: none;
    }

    .rbc-btn-group {
      position: fixed;
      bottom: 20px;
      left: 50%;
      transform: translateX(-50%);
      z-index: 110;

      @media only screen and (max-width: 650px) {
        right: 20px;
        left: unset;
        transform: unset;
        bottom: 100px;
      }

      button {
        padding: 10px;
        background-color: var(--white);
        cursor: pointer;

        .icon {
          max-width: 18px;
          width: 100%;
          color: var(--green);
        }
      }
    }

    .rbc-date-cell {
      text-align: center;

      button {
        font-size: 1.2rem;
      }
    }

    .rbc-event-content {
      overflow: hidden !important;
    }

    @media only screen and (max-width: 650px) {
      padding: 0;
    }

    .rbc-button-link {
      font-size: 1.4rem;
      font-weight: 600;
      padding: 15px;
    }

    .rbc-header {
      padding: 20px;
    }

    .rbc-event {
      background-color: transparent !important;
    }

    .rbc-today {
      background-color: var(--lightgreen) !important;
    }

    .rbc-toolbar-label {
      margin: 40px 0 20px;
      font-weight: 600;
      text-transform: capitalize;
      font-size: 1.2rem;
      color: var(--green);
    }
  }
`;

export default Calendar;
