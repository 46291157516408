import React, { useRef } from "react";
import styled from "styled-components";
import { motion, useInView } from "framer-motion";

//locales
import { useTranslation } from "react-i18next";

const VideoBanner = () => {
  const { t } = useTranslation();
  const ref = useRef(null);
  const isInView = useInView(ref, { once: true });

  const variants = {
    hidden: { opacity: 0, y: 50 },
    visible: { opacity: 1, y: 0, transition: { duration: 0.6 } },
  };

  return (
    <Wrapper
      id="video-banner"
      ref={ref}
      initial="hidden"
      animate={isInView ? "visible" : "hidden"}
      variants={variants}
    >
      <div className="video__banner">
        <video autoPlay muted playsInline loop>
          <source src="/assets/home/video-banner.mp4" type="video/mp4" />
        </video>

        <div className="video__banner__background" />

        <div className="video__banner__text">
          <p className="text-3xl">{t("video_banner_text", { ns: "home" })}</p>
        </div>
      </div>
    </Wrapper>
  );
};

const Wrapper = styled(motion.section)`
  .video__banner {
    width: 100vw;
    height: 400px;
    position: relative;

    @media screen and (max-width: 600px) {
      height: 600px;

      &__text {
        p {
          line-height: 3rem !important;
        }
      }
    }

    &__background {
      width: 100%;
      height: 100%;
      background-color: black;
      opacity: 0.3;
      position: absolute;
      top: 0;
      left: 0;
    }

    &__text {
      height: 100%;
      padding: 0 0 0 10%;
      position: absolute;
      top: 0;
      left: 0;
      z-index: 10;
      display: flex;
      justify-content: center;
      align-items: center;
      flex-grow: 1;

      @media screen and (max-width: 700px) {
        padding: 0 20px !important;
      }

      p {
        color: var(--white);
        text-align: left;
        font-weight: 600;
        line-height: 2.5rem;
        max-width: 600px;

        @media screen and (max-width: 600px) {
          text-align: left;
        }
      }
    }

    video {
      width: 100vw;
      height: 400px;
      object-fit: cover;

      @media screen and (max-width: 600px) {
        height: 600px;
      }
    }
  }
`;

export default VideoBanner;
