import { eachDayOfInterval } from "date-fns";

const generateDisabledDates = () => {
    const currentYear = new Date().getFullYear();
    
    // Date ranges to disable (current year)
    const startDate1 = new Date(currentYear, 9, 2);  
    const endDate1 = new Date(currentYear, 11, 31);  
  
    const startDate2 = new Date(currentYear + 1, 0, 1);  
    const endDate2 = new Date(currentYear + 1, 3, 30);
  
    const disabledDates1 = eachDayOfInterval({ start: startDate1, end: endDate1 });
    const disabledDates2 = eachDayOfInterval({ start: startDate2, end: endDate2 });
  
    return [...disabledDates1, ...disabledDates2];
  };

  export default generateDisabledDates;
  